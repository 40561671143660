import { IShopifyData } from "../../ShopifyData";

// Generate Email Header
export function createDefaultEmailHeader(shopifyData: IShopifyData) {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html
	xmlns="http://www.w3.org/1999/xhtml"
	xmlns:v="urn:schemas-microsoft-com:vml"
	xmlns:o="urn:schemas-microsoft-com:office:office"
>
	<head>
		<!--[if gte mso 9]>
			<xml>
				<o:OfficeDocumentSettings>
					<o:AllowPNG />
					<o:PixelsPerInch>96</o:PixelsPerInch>
				</o:OfficeDocumentSettings>
			</xml>
		<![endif]-->
		<meta http-equiv="Content-type" content="text/html; charset=utf-8" />
		<meta
			name="viewport"
			content="width=device-width, initial-scale=1, maximum-scale=1"
		/>
		<meta http-equiv="X-UA-Compatible" content="IE=edge" />
		<meta name="format-detection" content="date=no" />
		<meta name="format-detection" content="address=no" />
		<meta name="format-detection" content="telephone=no" />
		<meta name="x-apple-disable-message-reformatting" />
		<title>Email Template</title>
		<!--[if gte mso 9]>
			<style type="text/css" media="all">
				sup {
					font-size: 100% !important;
				}
			</style>
		<![endif]-->

		<style type="text/css" media="screen">
			body {
				padding: 0 !important;
				margin: 0 auto !important;
				display: block !important;
				min-width: 100% !important;
				width: 100% !important;
				background: <%- theming.colors.backgroundColor %>;
				-webkit-text-size-adjust: none;
			}
			a {
				color:<%- theming.colors.primaryTextColor %>;
				text-decoration: none;
			}
			p {
				margin: 0 !important;
			}
			img {
				margin: 0 !important;
				-ms-interpolation-mode: bicubic; /* Allow smoother rendering of resized image in Internet Explorer */
			}
			strong {
				font-weight: 700 !important;
			}
			.main-tb div {
				background: transparent !important;
			}
			#MessageViewBody,
			#MessageWebViewDiv {
				width: 100% !important;
			} /* Samsung Galaxy Note 4 Samsung Mail - make email stay centered */
			.tdn a {
				text-decoration: none !important;
			}

			.btnp-5-10 a {
				display: inline-block;
				padding: 5px 10px;
				text-decoration: none;
			}
			.btnp-10-20 a {
				display: inline-block;
				padding: 10px 20px;
				text-decoration: none;
			}

			.l-white a,
      .l-grey7a a,
      .l-grey4d a,
      .l-grey44 a {
				color:<%- theming.colors.primaryTextColor %>;
			}

      .show-on-mobile {
        display: none;
      }

      .show-on-desktop {
        display: unset;
      }

			/* Mobile styles */
			@media only screen and (max-device-width: 480px),
				only screen and (max-width: 480px) {
				u + .body .main-tb {
					padding-bottom: 30px;
				}

				body,
				.body {
					background-color:<%- theming.colors.backgroundColor %> !important;
				}

				.mt-left {
					text-align: left !important;
				}
				.mt-center {
					text-align: center !important;
				}
				.mt-right {
					text-align: right !important;
				}

				.me-left {
					margin-right: auto !important;
				}
				.me-center {
					margin: 0 auto !important;
				}
				.me-right {
					margin-left: auto !important;
				}

				.mh-auto {
					height: auto !important;
				}
				.mw-auto {
					width: auto !important;
				}

				.fluid-img img {
					width: 100% !important;
					max-width: 100% !important;
					height: auto !important;
					max-height: auto !important;
				}

				.column,
				.column-top {
					float: left !important;
					width: 100% !important;
					display: block !important;
				}

				.m-hide {
					display: none !important;
					width: 0 !important;
					height: 0 !important;
					font-size: 0 !important;
					line-height: 0 !important;
					min-height: 0 !important;
				}
				.m-block {
					display: block !important;
				}

				.mw-50 {
					width: 50px !important;
				}

				.mw-100p {
					width: 100% !important;
				}

        .show-on-mobile {
          display: unset;
        }

        .show-on-desktop {
          display: none;
        }

			}
			@media screen and (max-device-width: 350px),
				only screen and (max-width: 350px) {
				.mmpx-15 {
					padding-left: 15px !important;
					padding-right: 15px !important;
				}
			}
		</style>
	</head>
	<body
		class="body"
		style="
			padding: 0 !important;
			margin: 0 auto !important;
			display: block !important;
			min-width: 100% !important;
			width: 100% !important;
			background: #fffffe;
			-webkit-text-size-adjust: none;
		"
	>
		<center>
			<table
				class="main-tb"
				width="100%"
				border="0"
				cellspacing="0"
				cellpadding="0"
				style="margin: 0; padding: 0; width: 100%; height: 100%"
				bgcolor="<%- theming.colors.backgroundColor %>"
			>
				<tr>
					<td
						style="margin: 0; padding: 0; width: 100%; height: 100%"
						align="center"
						valign="top"
					>
						<table
							class="mw-100p"
							width="600"
							border="0"
							cellspacing="0"
							cellpadding="0"
						>
							<tr>
								<td
									class="pt-60 px-30 mmpx-15"
									style="
										font-size: 0pt;
										line-height: 0pt;
										<% if(theming.useHeaderAndFooter){ %> padding-top: 60px; <% } %>
										padding-left: 30px;
										padding-right: 30px;
									"
								>
									<table
										width="100%"
										border="0"
										cellspacing="0"
										cellpadding="0"
									>
									<% if (theming.useHeaderAndFooter){ %>
                    	<!-- Header -->
                        <tr>
                          <td
                            class="text fz-20 lh-23 pb-30"
                            style="
                              border-bottom: 1px solid
                                #ececec;
                              color:<%- theming.colors.primaryTextColor %>;
                              font-family: Arial,
                                sans-serif;
                              text-align: left;
                              mso-line-height-rule: exactly;
                              font-size: 20px;
                              line-height: 23px;
                              padding-bottom: 30px;
                            "
                          >
                          <% if(theming.logo){ %>
                            <img src="<%- theming.logo %>" width="<%- theming.logoWidth %>" alt="${shopifyData.name} Logo" />
                            <% } else {%>
                              ${shopifyData.name}
                              <% } %>
                          </td>
                        </tr>
                        <!-- END Header -->
                    <% } %>
`;
}

export function createDefaultEmailFooter(shopifyData: IShopifyData) {
  return `
            <% if (theming.useHeaderAndFooter){ %>
                    <!-- Footer -->
										<tr>
											<td
												class="pt-30 pb-55 tdn"
												style="
													padding-top: 30px;
													padding-bottom: 55px;
												"
											>
												<table
													width="100%"
													border="0"
													cellspacing="0"
													cellpadding="0"
												>
													<tr>
														<td
															class="text fz-12 lh-16 c-grey7a l-grey7a pb-5"
															style="
																font-family: Arial,
																	sans-serif;
																text-align: left;
																mso-line-height-rule: exactly;
																font-size: 12px;
																line-height: 16px;
																color:<%- theming.colors.secondaryTextColor %>;
																padding-bottom: 5px;
															"
														>
                                                    ${shopifyData.name}
														</td>
													</tr>
													<tr>
														<td
															class="text fz-12 lh-16 c-grey7a l-grey7a pb-5"
															style="
																font-family: Arial,
																	sans-serif;
																text-align: left;
																mso-line-height-rule: exactly;
																font-size: 12px;
																line-height: 16px;
																color:<%- theming.colors.secondaryTextColor %>;
																padding-bottom: 5px;
															"
														>
															${shopifyData.address1 + shopifyData.address2}, ${shopifyData.city}, ${shopifyData.province} ${shopifyData.zip}
														</td>
													</tr>
													<tr>
														<td
															class="text fz-12 lh-16 c-grey7a l-grey7a pb-5"
															style="
																font-family: Arial,
																	sans-serif;
																text-align: left;
																mso-line-height-rule: exactly;
																font-size: 12px;
																line-height: 16px;
																color:<%- theming.colors.secondaryTextColor %>;
																padding-bottom: 5px;
															"
														>
															<a href="${
                                shopifyData.customer_email
                              }" target="_blank" style="color:<%- theming.colors.secondaryTextColor %> !important;">${
                                shopifyData.customer_email
                              }</a>
														</td>
													</tr>
												</table>
											</td>
										</tr>
										<!-- END Footer -->
                                    <% } %>
									</table>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</center>
	</body>
</html>

`;
}
