import { TextField } from "@shopify/polaris";
import React from "react";
import { StyledEmailInputContainer } from "./constants";

interface EmailPreviewModalInputProps {
  email: string;
  subject: string | undefined;
}

export const EmailPreviewEmailInput = ({ email, subject }: EmailPreviewModalInputProps) => {
  return (
    <StyledEmailInputContainer>
      <TextField label="Email Recipient" type="email" autoComplete="off" value={email} disabled readOnly />
      {subject ? (
        <TextField label="Email Subject" type="text" autoComplete="off" value={subject} disabled readOnly />
      ) : (
        <React.Fragment />
      )}
    </StyledEmailInputContainer>
  );
};
