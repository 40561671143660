import React from "react";

import { IShopifyData } from "../../../../ShopifyData";
import { Section, Link } from "@react-email/components";
import { BodySm } from "../../../components/Text";
import { formatTemplateVariable } from "../../../utils";

export const HaveAQuestionSection = ({ shopifyData }: { shopifyData: IShopifyData }) => {
  return (
    <Section>
      <BodySm
        style={{
          color: formatTemplateVariable("theming.colors.primaryTextColor"),
          paddingBottom: "30px",
          margin: 0,
        }}
      >
        Have a question? Email us at{" "}
        <Link
          href={`mailTo:${shopifyData.email}`}
          style={{
            color: formatTemplateVariable("theming.colors.primaryTextColor"),
            textDecoration: "underline",
          }}
        >
          {shopifyData.email}
        </Link>
      </BodySm>
    </Section>
  );
};
