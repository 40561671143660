import { Container, Html, Section } from "@react-email/components";
import React from "react";
import { EmailHead } from "./EmailHead";
import SMARTRR_COLORS from "../../../branding/colors";
import { formatTemplateVariable } from "../utils";

interface BasicCustomerEmailFrameProps {
  /** Email Title */
  emailTitle: string;

  /** Preview text that will be displayed in the inbox of the recipient */
  emailPreviewText: string;

  /** Contents to be displayed within email card */
  children: JSX.Element[];
}

const BasicCustomerEmailFrame = ({ emailPreviewText, children, emailTitle }: BasicCustomerEmailFrameProps) => {
  return (
    <Html>
      <EmailHead emailPreviewText={emailPreviewText} emailTitle={emailTitle} />
      <body>
        <Container
          id="customer-email-frame"
          style={{
            backgroundColor: SMARTRR_COLORS.lightGrey,
            fontFamily: "Arial, sans-serif",
            maxWidth: "100%",
          }}
        >
          <Container
            style={{
              maxWidth: "600px",
            }}
          >
            <Section
              style={{
                backgroundColor: formatTemplateVariable("theming.colors.backgroundColor") ?? SMARTRR_COLORS.white,
                borderRadius: "10px",
                width: "600px",
              }}
            >
              <div style={{ padding: "30px" }}>{children}</div>
            </Section>
          </Container>
        </Container>
      </body>
    </Html>
  );
};

export default BasicCustomerEmailFrame;
