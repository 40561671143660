import { isEmpty } from "lodash";

export const emailSubduedNote = (text: string): string =>
  `<% if(${!isEmpty(text)}){ %>
    <tr>
      <td class="text fz-16 lh-19 c-grey4d l-grey4d pb-30" style="font-family:Arial, sans-serif; text-align:left; mso-line-height-rule:exactly; font-size: 12px; line-height: 16px; color:<%- theming.colors.secondaryTextColor %>; padding-bottom: 30px;">
        ${text}
      </td>
	  </tr>
  <% } %>`;
