import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailQuestionSection } from "../../../components/emailQuestions";

export function createDefaultItemOutOfStockEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(
      `One of your items<% if(outOfStockItem.unavailableReason === 'out_of_stock'){ %> is out of stock <% } else { %> is no longer available <% } %>`
    ),
    emailBodySection(
      `<%- outOfStockItem.name %> <% if(outOfStockItem.variantName !== 'Default Title'){ %>- <%- outOfStockItem.variantName %><% } %><% if(outOfStockItem.unavailableReason === 'out_of_stock'){ %> is currently out of stock<% } else { %> is no longer available<% } %>. Your next subscription order has been paused.`
    ),
    emailBodySection(
      "To resume immediately, you can update to a different product for your next order by managing your subscription below."
    ),
    emailButton("Manage my subscription", `https://${shopifyData.domain}/account`),
    emailQuestionSection(shopifyData)
  );
}
