import React from "react";
import BasicMerchantEmailFrame from "@smartrr/shared/entities/CustomerEmailConfig/components/BasicMerchantEmailFrame";
import { BodyLg, BodySm, HeadingSm } from "@smartrr/shared/entities/CustomerEmailConfig/components/Text";
import { RoundedButton } from "@smartrr/shared/entities/CustomerEmailConfig/components/RoundedButton";
import { appPrefixedHost } from "@smartrr/shared/constants";
import { formatTemplateVariable } from "@smartrr/shared/entities/CustomerEmailConfig/utils";
import SMARTRR_COLORS from "@smartrr/shared/branding/colors";

export interface EmailTemplateMigration {
  fileName: string;
  completionDate: string;
  successNum: number;
  failedNum: number;
  shopifyDomain: string;
}

/**
 * `<%- migration.VARIABLE %>`
 * @param migration.fileName name of imported file
 * @param migration.completionDate UTC date string of migration completion
 * @param migration.successNum number of successfully migrated subscriptions
 * @param migration.failedNum number of subscriptions that have failed migrating
 * @param migration.shopifyDomain organization shopify domain
 */
export const MigrationSummary = () => {
  return (
    <BasicMerchantEmailFrame
      emailPreviewText="Example migration summary"
      emailTitle="Migration Summary"
      showSmartrrBanner
      showQuestionsFooter
    >
      <span>
        <HeadingSm data-testid="migration-summary__header" style={{ marginTop: 0, paddingBottom: "10px" }}>
          Your migration is complete
        </HeadingSm>
        <BodySm
          data-testid="migration-summary__file-name"
          style={{ margin: 0, color: SMARTRR_COLORS.subduedGrey }}
        >
          {formatTemplateVariable("migration.fileName")}
        </BodySm>
        <BodySm
          data-testid="migration-summary__completion-date"
          style={{ margin: 0, paddingBottom: "20px", color: SMARTRR_COLORS.subduedGrey }}
        >
          {formatTemplateVariable("migration.completionDate")}
        </BodySm>
        <BodySm data-testid="migration-summary__success-num" style={{ margin: 0, color: SMARTRR_COLORS.success }}>
          {formatTemplateVariable("migration.successNum")} successful
        </BodySm>
        <BodySm
          data-testid="migration-summary__failed-num"
          style={{ margin: 0, paddingBottom: "20px", color: SMARTRR_COLORS.failure }}
        >
          {formatTemplateVariable("migration.failedNum")} failed
        </BodySm>
        <BodyLg data-testid="migration-summary__download-info" style={{ paddingBottom: "20px" }}>
          Download your migration summary to review a full report of this migrations&apos; successful and failed
          rows.
        </BodyLg>
        <RoundedButton
          data-testid="migration-summary__download-button"
          href={`https://${appPrefixedHost}/admin/configure/migrations?exportFile=${formatTemplateVariable("migration.fileName")}&shop=${formatTemplateVariable("migration.shopifyDomain")}`}
          style={{ maxWidth: "235px" }}
        >
          Download migration summary
        </RoundedButton>
      </span>
    </BasicMerchantEmailFrame>
  );
};
