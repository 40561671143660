import React from "react";
import BasicMerchantEmailFrame from "@smartrr/shared/entities/CustomerEmailConfig/components/BasicMerchantEmailFrame";
import { BodyLg, HeadingSm } from "@smartrr/shared/entities/CustomerEmailConfig/components/Text";
import { RoundedButton } from "@smartrr/shared/entities/CustomerEmailConfig/components/RoundedButton";
import { appPrefixedHost } from "@smartrr/shared/constants";

/**
 * @param currencies that was recently enabled
 * @param currenciesCount that was recently enabled
 */
export const CurrencyReminder = () => {
  return (
    <BasicMerchantEmailFrame
      emailPreviewText="Example currency reminder"
      emailTitle="Currency Reminder"
      showSmartrrBanner
      showQuestionsFooter
    >
      <React.Fragment>
        <HeadingSm style={{ paddingBottom: "20px" }}>
          {`
            <% if (currenciesCount === 1) { %>
              You have a new currency. Make sure to update your Loyalty settings accordingly. 💸
            <% } else { %>
              You have new currencies. Make sure to update your Loyalty settings accordingly. 💸
            <% } %>
          `}
        </HeadingSm>
        <BodyLg style={{ paddingBottom: "20px" }}>
          {`
            <% if (currenciesCount === 1) { %>
              It looks like you have a new currency (<%- currencies %>) available in Shopify. Set up a
              corresponding point multiplier today so your customers can redeem items for an appropriate point value
              for each currency on your Shopify store.
            <% } else { %>
              It looks like you have new currencies (<%- currencies %>) available in Shopify. Set
              up corresponding point multipliers today so your customers can redeem items for an appropriate point
              value for each currency on your Shopify store.
            <% } %>
          `}
        </BodyLg>

        <RoundedButton href={`https://${appPrefixedHost}/admin/loyalty`} style={{ maxWidth: "235px" }}>
          Open Smartrr
        </RoundedButton>
      </React.Fragment>
    </BasicMerchantEmailFrame>
  );
};
