import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailQuestionSection } from "../../../components/emailQuestions";

// Payment expiration notice
export function createDefaultExpiredPaymentMethodEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader("Your card is set to expire"),
    emailBodySection(
      "Hi <%- customer.firstName %>, your <%- subscription.cardType %> is set to expire next month. This card is currently being used for one of your subscriptions."
    ),
    emailBodySection(
      "To ensure your subscription is uninterrupted, please make sure to update your payment method in your account."
    ),
    emailButton("Update payment method", `https://${shopifyData.domain}/account`),
    emailQuestionSection(shopifyData)
  );
}
