import React from "react";
import { IShopifyData } from "@smartrr/shared/entities/ShopifyData";
import BasicCustomerEmailFrame from "@smartrr/shared/entities/CustomerEmailConfig/components/BasicCustomerEmailFrame";
import { BodyLg, HeadingLg } from "@smartrr/shared/entities/CustomerEmailConfig/components/Text";
import { emailBodySection } from "@smartrr/shared/entities/CustomerEmailConfig/components/emailBody";
import { emailButton } from "@smartrr/shared/entities/CustomerEmailConfig/components/emailButton";
import { emailFrame } from "@smartrr/shared/entities/CustomerEmailConfig/components/emailFrame";
import { emailHeader } from "@smartrr/shared/entities/CustomerEmailConfig/components/emailHeader";
import { emailQuestionSection } from "@smartrr/shared/entities/CustomerEmailConfig/components/emailQuestions";
import { formatTemplateVariable } from "@smartrr/shared/entities/CustomerEmailConfig/utils";
import { RoundedButton } from "@smartrr/shared/entities/CustomerEmailConfig/components/RoundedButton";
import { ShopHeaderWithLogo } from "@smartrr/shared/entities/CustomerEmailConfig/emails/CustomerEmails/components/ShopHeaderWithLogo";
import { ShopFooterWithAddress } from "@smartrr/shared/entities/CustomerEmailConfig/emails/CustomerEmails/components/ShopFooterWithAddress";

// Subscription created - Existing customer
export function createDefaultSubscriptionCreateEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader("Your subscription is confirmed"),
    emailBodySection(
      `Hi <%- customer.firstName %>, this email confirms your recurring subscription from ${shopifyData.name}.`
    ),
    emailBodySection(
      "To manage your subscription in the future, simply log into the account associated with your email: <%- customer.email %>."
    ),
    emailQuestionSection(shopifyData),
    emailButton("Log into my account", `https://${shopifyData.domain}/account`)
  );
}

/**
 * @param shopifyData `IShopifyData`
 * @param customer.firstName customer's first name
 * @param customer.email customer's email
 */
export const SubscriptionCreateEmail = ({ shopifyData }: { shopifyData: IShopifyData }) => (
  <BasicCustomerEmailFrame emailTitle="Subscription Created" emailPreviewText="Your subscription is confirmed">
    <ShopHeaderWithLogo shopifyData={shopifyData} />
    <HeadingLg
      data-testid="subscription-created__header"
      style={{ color: formatTemplateVariable("theming.colors.primaryTextColor") }}
    >
      Your subscription is confirmed
    </HeadingLg>
    <BodyLg
      data-testid="subscription-created__confirmation-body"
      style={{ padding: "0 0 30px", color: formatTemplateVariable("theming.colors.primaryTextColor") }}
    >
      Hi {formatTemplateVariable("customer.firstName")}, this email confirms your recurring subscription from{" "}
      {shopifyData.name}.
    </BodyLg>
    <BodyLg
      data-testid="subscription-created__manage-sub-body"
      style={{ padding: "0 0 30px", color: formatTemplateVariable("theming.colors.primaryTextColor") }}
    >
      To manage your subscription in the future, simply log into the account associated with your email:{" "}
      {formatTemplateVariable("customer.email")}.
    </BodyLg>

    <RoundedButton
      data-testid="subscription-created__account-cta"
      href={`https://${shopifyData.domain}/account`}
      style={{
        width: "100%",
        marginBottom: "30px",
        background: formatTemplateVariable("theming.colors.buttonBackgroundColor"),
        color: formatTemplateVariable("theming.colors.buttonTextColor"),
      }}
    >
      Log into my account
    </RoundedButton>
    <ShopFooterWithAddress shopifyData={shopifyData} />
  </BasicCustomerEmailFrame>
);
