import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailQuestionSection } from "../../../components/emailQuestions";

// Subscription canceled
export function createDefaultCancelEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader("We're sorry to see you go"),
    emailBodySection(`This email confirms your subscription has been canceled. We hope you come back soon.`),
    emailButton(`Shop ${shopifyData.name}`, `https://${shopifyData.domain}/`),
    emailQuestionSection(shopifyData)
  );
}
