import React from "react";
import { EmailTemplateCustomer, ICustomerEmailTheming } from "../../../../../types";
import { LoyaltyApi } from "../../../../../../../interfaces/loyalty/api";
import { IShopifyData } from "../../../../../../ShopifyData";
import BasicCustomerEmailFrame from "../../../../../components/BasicCustomerEmailFrame";
import { ShopHeaderWithLogo } from "../../../components/ShopHeaderWithLogo";
import { BodyLg, HeadingLg } from "../../../../../components/Text";
import { formatTemplateVariable } from "../../../../../utils";
import { getLoyaltyPointsName } from "../../components";
import { HaveAQuestionSection } from "../../../components/HaveAQuestionSection";
import { ShopFooterWithAddress } from "../../../components/ShopFooterWithAddress";
import { RoundedButton } from "../../../../../components/RoundedButton";
import { TierProgressBarSection } from "../../components/TierProgressBarSection";

export interface TierAdvancementEmailTemplateContextProps {
  customer: EmailTemplateCustomer;
  loyalty: {
    customerBalance: number;
    customerTierBalance: number;
    currencySymbol: string;
    tiers: {
      currentTier: LoyaltyApi.Tier.Type;
      nextTier: LoyaltyApi.Tier.Type | null;
    };
  };
  program: LoyaltyApi.Program.Type;
  theming: ICustomerEmailTheming;
}

/**
 * `<%- loyalty.VARIABLE %>`,
 * `<%- customer.VARIABLE %>`,
 * `<%- program.VARIABLE %>`,
 * @param loyalty.customerBalance - newly updated point balance from custRel
 * @param program.rewardUnitNamePlural -  plural reward unit name
 * @param program.rewardUnitNameSingular - singular reward unit name
 * @param program.events - point generation events, see `dummyRewards` within [`shared/entities/CustomerEmailConfig/mocks.ts`](../../../mocks.ts)
 * @param program.referralProgram.pointsInReward - points distributed after referring
 * @param program.bonus.givePoints - points distributed after hitting nth order
 * @param program.bonus.every - nth order
 */
export function LoyaltyTierAdvancementEmail({ shopifyData }: { shopifyData: IShopifyData }) {
  return (
    <BasicCustomerEmailFrame
      emailPreviewText={`${formatTemplateVariable("customer.firstName")}, you&apos;re moving up!`}
      emailTitle={`${formatTemplateVariable("customer.firstName")}, you&apos;re moving up!`}
    >
      <ShopHeaderWithLogo shopifyData={shopifyData} />
      <HeadingLg
        data-testid="loyalty-tier-advancement-email_header"
        style={{ color: formatTemplateVariable("theming.colors.primaryTextColor") }}
      >
        {formatTemplateVariable("customer.firstName")}, you&apos;re moving up!
      </HeadingLg>
      <TierProgressBarSection
        title={`Welcome to ${formatTemplateVariable("loyalty.tiers.currentTier.tierName")} tier`}
        showSubtitleCopy={false}
      />
      <div>
        {`<% if(loyalty.tiers.nextTier){ %>`}
        <BodyLg
          style={{
            margin: "0 0 20px",
            color: formatTemplateVariable("theming.colors.primaryTextColor"),
          }}
        >
          You&apos;re now in our {formatTemplateVariable("loyalty.tiers.currentTier.tierName")} tier, which means
          you get access to even more rewards you can use on your next order.
        </BodyLg>
        <BodyLg
          style={{
            margin: "0 0 20px",
            color: formatTemplateVariable("theming.colors.primaryTextColor"),
          }}
        >
          Earn {`<%- Number(loyalty.tiers.nextTier.minimumPoints) - Number(loyalty.customerTierBalance) %>`} more{" "}
          {getLoyaltyPointsName("loyalty.customerBalance")} before December 31 to move up to{" "}
          {formatTemplateVariable("loyalty.tiers.nextTier.tierName")} tier.
        </BodyLg>
        {`<% } else { %>`}
        <BodyLg
          style={{
            margin: "0 0 20px",
            color: formatTemplateVariable("theming.colors.primaryTextColor"),
          }}
        >
          You&apos;re now in our {formatTemplateVariable("loyalty.tiers.currentTier.tierName")} tier, which means
          you get access to even more rewards you can use on your next order.
        </BodyLg>
        <BodyLg
          style={{
            margin: "0 0 20px",
            color: formatTemplateVariable("theming.colors.primaryTextColor"),
          }}
        >
          🤩 You&apos;re at the top tier for the year - thank you for being a valued customer!
        </BodyLg>
        {`<% } %>`}
      </div>
      <RoundedButton
        data-testid="loyalty-tier-advancement-email_view-rewards-button"
        href={`https://${shopifyData.domain}/account`}
        style={{
          width: "540px",
          marginBottom: "30px",
          background: formatTemplateVariable("theming.colors.buttonBackgroundColor"),
          color: formatTemplateVariable("theming.colors.buttonTextColor"),
        }}
      >
        View all rewards
      </RoundedButton>
      <HaveAQuestionSection shopifyData={shopifyData} />
      <ShopFooterWithAddress shopifyData={shopifyData} />
    </BasicCustomerEmailFrame>
  );
}
