import { Container, Html, Link, Section, Text } from "@react-email/components";
import React from "react";
import { SmartrrLogoEmailBanner } from "./SmartrrLogoEmail";
import { EmailHead } from "./EmailHead";

interface BasicMerchantEmailFrameProps {
  /** Email Title */
  emailTitle: string;

  /** Preview text that will be displayed in the inbox of the recipient */
  emailPreviewText: string;

  /** Contents to be displayed within email card */
  children: JSX.Element;

  /** Hide / Shows Smartrr Logo banner */
  showSmartrrBanner: boolean;

  /** Hides / Shows Questions footer section */
  showQuestionsFooter: boolean;
}

const BasicMerchantEmailFrame = ({
  emailPreviewText,
  children,
  showSmartrrBanner,
  emailTitle,
  showQuestionsFooter,
}: BasicMerchantEmailFrameProps) => {
  return (
    <Html>
      <EmailHead emailPreviewText={emailPreviewText} emailTitle={emailTitle} />
      <body>
        <Container
          style={{
            backgroundColor: "#ededed",
            fontFamily: "Arial, sans-serif",
            maxWidth: "100%",
            padding: "20px 20px",
          }}
        >
          <Container
            style={{
              maxWidth: "600px",
              width: "100% !important",
            }}
          >
            <Section>
              {!!showSmartrrBanner && <SmartrrLogoEmailBanner />}
              <Section>
                <Section
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    width: "600px",
                  }}
                >
                  <center style={{ padding: "40px" }}>{children}</center>
                </Section>
                {!!showQuestionsFooter && (
                  <Section
                    style={{
                      marginTop: "40px",
                    }}
                  >
                    <center>
                      <Text>
                        Questions about this email? Get in touch:{" "}
                        <Link href="mailto:help@smartrr.com">help@smartrr.com</Link>
                      </Text>
                    </center>
                  </Section>
                )}
              </Section>
            </Section>
          </Container>
        </Container>
      </body>
    </Html>
  );
};

export default BasicMerchantEmailFrame;
