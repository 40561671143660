import { EmailRecipientType, IEmailPreview } from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { EmailCardSection, EmailsWithMappedSections } from "../../constants";
import useGenerateEmailConfigOptions, { CustomerEmailConfigOptions } from "../useGenerateEmailConfigOptions";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useIsFeatureAvailable } from "@vendor-app/app/AdminRoute/components/authorization/featureAccess";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { useMemo } from "react";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";
import { EmailSectionsEnum } from "@smartrr/shared/entities/CustomerEmailConfig";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { Link, Text } from "@shopify/polaris";
import { flatten, isNumber, merge } from "lodash";
import { IOrganization } from "@smartrr/shared/entities/Organization";

export const useEmails = ({
  defaults,
  emailRecipient,
  allowShopifyEmails,
}: {
  defaults: boolean;
  emailRecipient: EmailRecipientType;
  allowShopifyEmails?: boolean;
}): {
  formattedSectionsWithEmails: EmailsWithMappedSections[];
  emailsSortedBySection: CustomerEmailConfigOptions;
} => {
  const activeOrg = useActiveOrganizationSelector();
  const customerEmailConfigFormattedRecord = useGenerateEmailConfigOptions({
    defaults,
    emailRecipient,
    allowShopifyEmails,
  });
  const isPasswordlessAvailable = useIsFeatureAvailable(FeatureEnum.PASSWORDLESS);
  const { migrationEmailsEnabled } = useSmartrrFlags();
  const sections = getSections(activeOrg);

  // Expanding sections based on conditions
  if (migrationEmailsEnabled) {
    sections.push({
      title: "Migrations",
      section: EmailSectionsEnum.MIGRATIONS,
    });
  }

  if (isPasswordlessAvailable) {
    sections.splice(4, 0, {
      title: "Passwordless Login",
      section: EmailSectionsEnum.PASSWORDLESS_LOGIN,
      description: (
        <Text as="p" variant="bodyMd">
          The below emails will only send if passwordless login is enabled in{" "}
          <Link onClick={onThemingClick}>Theming</Link>. Only the email for the passwordless login type you have
          chosen (one-time code or magic link) will trigger.
        </Text>
      ),
    });
  }

  // Mapping sections to their corresponding emails
  const formattedSectionsWithEmails = useMemo(
    () => mapEmailsToSections(customerEmailConfigFormattedRecord, sections),
    [customerEmailConfigFormattedRecord]
  );

  // Stripping emails of their sections but maintaining their order
  const emailsSortedBySection: CustomerEmailConfigOptions = useMemo(
    () => merge({}, ...flatten(formattedSectionsWithEmails.map(section => section.emails))),
    [formattedSectionsWithEmails]
  );

  return { formattedSectionsWithEmails, emailsSortedBySection };
};

const mapEmailsToSections = (
  configs: CustomerEmailConfigOptions,
  sections: EmailCardSection[]
): EmailsWithMappedSections[] => {
  const arr: { section: EmailCardSection; emails: { [x: string]: IEmailPreview }[] }[] = [];
  for (const [emailType, emailConfig] of Object.entries(configs)) {
    const correspondingSection = sections.find(sectionConfig => sectionConfig.section === emailConfig.section);

    if (!correspondingSection) {
      continue;
    }
    const preexistingEntryIndex = arr.findIndex(
      formattedSection => formattedSection.section.section === correspondingSection.section
    );

    if (isNumber(preexistingEntryIndex) && arr[preexistingEntryIndex]) {
      arr[preexistingEntryIndex].emails.push({ [emailType]: emailConfig });
    } else {
      arr.push({ emails: [{ [emailType]: emailConfig }], section: correspondingSection });
    }
  }

  return arr.sort((a, b) => a.section.section - b.section.section);
};

const onNotificationSettingsClick = (org: IOrganization | null) => {
  if (!org?.myShopifyDomain) {
    return;
  }
  frontEndTabOpen(
    `https://admin.shopify.com/store/${org.myShopifyDomain?.split(".myshopify.com")[0]}/settings/notifications`
  );
};

const onThemingClick = () => navigateWithShopInQuery(`/admin/configure/theming`);

const getSections = (org: IOrganization | null) => {
  const sections: EmailCardSection[] = [
    {
      title: "New subscription",
      section: EmailSectionsEnum.NEW_SUBSCRIPTION,
    },
    {
      title: "Subscription management",
      section: EmailSectionsEnum.SUBSCRIPTION_MANAGEMENT,
    },
    {
      title: "Loyalty and referrals",
      section: EmailSectionsEnum.REFERRALS,
    },
    {
      title: "Gifted subscriptions",
      section: EmailSectionsEnum.GIFTED_SUBSCRIPTIONS,
    },
    {
      title: "Sent from Shopify",
      section: EmailSectionsEnum.SENT_FROM_SHOPIFY,
      description: (
        <Text as="p" variant="bodyMd">
          Visit <Link onClick={() => onNotificationSettingsClick(org)}>notification settings</Link> in Shopify
          admin to configure the following emails.
        </Text>
      ),
    },
    {
      title: "Insights",
      section: EmailSectionsEnum.INSIGHTS,
    },
  ];
  return sections;
};
