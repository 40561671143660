import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailProductData } from "../../../components/emailProductData";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubHeader } from "../../../components/emailSubHeader";

export function createDefaultAutoResumeSubscriptionEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(
      `Your subscription is set to auto-resume <% if(subscription.daysTillUnpause === 0){ %>today<% } else { %>in <%- subscription.daysTillUnpause %> day<% if(subscription.daysTillUnpause > 1) { %>s<% } %><% }%>`
    ),
    emailSubHeader("ITEMS SET TO AUTO-RESUME:"),
    emailProductData(),
    emailBodySection(
      `Hi <%- customer.firstName %>, we wanted to remind you that your paused subscription is set to auto-resume on <%- subscription.unpauseDate%>. Need more time before your next delivery? Change your auto-resume date in your account below:`
    ),
    emailButton("View my account", `https://${shopifyData.domain}/account?subscription=<%- subscription.id%>`),
    emailQuestionSection(shopifyData)
  );
}
