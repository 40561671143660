import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubduedNote } from "../../../components/emailSubduedNote";

export function createDefaultPaymentFailureEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(`Uh-oh, there was a problem with your payment`),
    emailBodySection(
      `Your most recent payment for subscription #<%- subscription.id %> has failed. Reason: <%- failedPayment.reason ?? "" %>. <br/> Please try updating your payment method.`
    ),
    emailButton(
      "Update payment method",
      `https://${shopifyData.domain}/account?subscription=<%- subscription.id %>`
    ),
    emailSubduedNote(
      "<b>Note:</b> If you try to process your order again manually, you may risk being billed twice. Please continue to wait for our automatic retry even if you've updated your payment method."
    ),
    emailQuestionSection(shopifyData)
  );
}
