import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { getLoyaltyPointsName } from "./components";

export function createDefaultSuccessfulReferralEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(`<%- referrals.referee.firstName %> has used your referral link!`),
    emailBodySection(
      `As a thank you, we've added <%- referrals.pointsEarned %> ${getLoyaltyPointsName(
        "referrals.pointsEarned"
      )} to your balance. <span style="font-weight:bold">Current balance:</span> <%- referrals.pointBalance %> ${getLoyaltyPointsName(
        "referrals.pointBalance"
      )}.`
    ),
    emailButton("Redeem points", `https://${shopifyData.domain}/account`),
    emailQuestionSection(shopifyData)
  );
}
