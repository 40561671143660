import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import {
  purchasableRandomProductA,
  purchasableRandomProductB,
} from "@smartrr/shared/tests/mocks/purchasableMocks/basic";

export const tiersMock = [
  {
    id: "62101cff-4bd8-4efd-a5a4-527efe2d5be5",
    tierName: "Bronze",
    tierColor: "#CD7F32",
    tierRank: 1,
    incentives: null,
    minimumPoints: 0,
    products: null,
  },
  {
    id: "06663b66-9b35-403d-8fe9-cb492ae868a0",
    tierName: "Silver",
    tierColor: "#B9BCBD",
    tierRank: 2,
    incentives: null,
    minimumPoints: 250,
    products: null,
  },
  {
    id: "b1fba433-fd1a-4f6d-8d2c-95a6bae71f3d",
    tierName: "Gold",
    tierColor: "#FED600",
    tierRank: 3,
    incentives: null,
    minimumPoints: 500,
    products: null,
  },
] as LoyaltyApi.Tier.Type[];

export const tiersIdMocks = tiersMock.map(tier => tier.id!);

export const loyaltyMock: LoyaltyApi.Program.Type = {
  id: "da87f565-2c5d-4e69-a0a0-e390919fc426",
  updatedDate: "2024-02-07T13:59:29.691Z",
  bonus: { every: 5, givePoints: 5 },
  description: "",
  status: "ENABLED",
  otpEnabled: false,
  tiersEnabled: true,
  events: [
    { type: "ACCOUNT_CREATED", pointsEarned: 56 },
    { type: "BILLING_SUCCESS", pointsEarned: 3 },
  ],
  currencies: [
    {
      currency: "USD",
      multiplier: 1,
    },
    {
      currency: "AED",
      multiplier: 2,
    },
    {
      currency: "AUD",
      multiplier: 5,
    },
    {
      currency: "CAD",
      multiplier: 10,
    },
    {
      currency: "CHF",
      multiplier: 0.15,
    },
    {
      currency: "CZK",
      multiplier: 10,
    },
    {
      currency: "DKK",
      multiplier: 0.001,
    },
    {
      currency: "EUR",
      multiplier: 0.8,
    },
    {
      currency: "GBP",
      multiplier: 0.9,
    },
    {
      currency: "HKD",
      multiplier: 1000,
    },

    {
      currency: "JPY",
      multiplier: 10000,
    },
    {
      currency: "KRW",
      multiplier: 100000,
    },
  ],
  incentives: [
    {
      rewardType: "INCENTIVE",
      id: "de0d580a-286b-4cb7-9606-9693954fdbc1",
      costInPoints: 5,
      color: "#673333",
      priceRule: { type: "percentage", value: "-5.0" },
      hidden: false,
      subscriberOnly: false,
      tiers: [],
    },
    {
      rewardType: "INCENTIVE",
      id: "a65dd82c-6387-4059-a605-3d4a5214e9a6",
      costInPoints: 2,
      color: "#6d5353",
      priceRule: { type: "fixed_amount", value: "-2" },
      hidden: false,
      subscriberOnly: true,
      tiers: [],
    },
  ],
  organizationId: "b418d662-eca3-45e6-b134-4ff57b5df6ef",
  variants: [
    {
      rewardType: "PRODUCT",
      id: "6dda1469-64c8-4f95-b50e-ee214cf81331",
      costInPoints: 5,
      hidden: false,
      subscriberOnly: false,
      tiers: [],
      variant: {
        id: purchasableRandomProductA.vnts![0].id,
        variantImages: [
          "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/blue-can.png?v=1703244639",
          "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/pink-can.png?v=1703244639",
          "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/yellow-can-unsplash.jpg?v=1703244639",
          "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/pink-can_33206551-593b-40ad-82bf-9f9738db3f20.png?v=1703244639",
        ],
        productName: "Generated Product A0000",
        variantName: "Medium",
        shopifyId: purchasableRandomProductA.vnts![0].shopifyId!,
      },
      variantId: purchasableRandomProductA.vnts![0].id,
      shopifyId: purchasableRandomProductA.vnts![0].shopifyId!,
    },
    {
      rewardType: "PRODUCT",
      id: "724da2f0-ad12-43df-8a39-fe7d0c04062a",
      costInPoints: 2,
      hidden: false,
      subscriberOnly: true,
      variant: {
        id: purchasableRandomProductB.vnts![0].id,
        variantImages: [
          "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/blue-can.png?v=1703244639",
          "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/pink-can.png?v=1703244639",
          "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/yellow-can-unsplash.jpg?v=1703244639",
          "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/pink-can_33206551-593b-40ad-82bf-9f9738db3f20.png?v=1703244639",
        ],
        productName: "Generated Product A0000",
        variantName: "Large",
        shopifyId: purchasableRandomProductB.vnts![0].shopifyId!,
      },
      variantId: purchasableRandomProductB.vnts![0].id,
      shopifyId: purchasableRandomProductB.vnts![0].shopifyId!,
      tiers: [],
    },
  ],
  referralProgram: {
    name: "Ref program",
    priceRule: { id: "00007759", type: "fixed_amount", value: "-7" },
    description: "Ref description",
    pointsInReward: 5,
    generatedDiscountCodes: [],
  },
  rewardSettings: { limitUse: 0, stackableDiscountCodes: false },
  rewardUnitNamePlural: "pointz",
  rewardUnitNameSingular: "point",
  tiers: tiersMock,
};

export const loyaltyAnalyticsMock: LoyaltyApi.Analytics.Type = {
  pointsAwarded: {
    percentageDifference: 10,
    sumInterval: 72,
  },
  pointsInCirculation: 1640,
  pointsRedeemed: {
    percentageDifference: 5,
    sumInterval: 150,
  },
};

export const referralCodeMock: LoyaltyApi.ReferralCode.Type = {
  code: "DISCOUNTSMARTRR000000111",
  link: "htttps://smartrr.myshopify.com/discount/DISCOUNTSMARTRR000000111",
};

export const shopifyVariantMock = purchasableRandomProductA.vnts![0];
