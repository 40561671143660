import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailProductData } from "../../../components/emailProductData";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubHeader } from "../../../components/emailSubHeader";

// Upcoming subscription
export function createDefaultUpcomingOrderEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(
      "Your next order is in <%- subscription.daysTillDelivery %> day<% if(subscription.daysTillDelivery > 1) { %>s<% } %>"
    ),
    emailSubHeader("COMING UP ON: <%- subscription.deliveryDate %>"),
    emailProductData(),
    emailBodySection("Hi <%- customer.firstName %>, thanks again for being a subscriber."),
    emailBodySection("Need to manage your order before it ships? Visit your account portal below."),
    emailButton("Manage my next order", `https://${shopifyData.domain}/account`),
    emailQuestionSection(shopifyData)
  );
}
