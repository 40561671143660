import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubduedNote } from "../../../components/emailSubduedNote";
import { emailLoyaltyItems, getLoyaltyPointsName } from "./components";

export function createDefaultBonusEarnedEmail(shopifyData: IShopifyData): string {
  return emailFrame(
    emailHeader(
      `You have earned an extra <%- bonusEarnedReminder.pointsEarned %> ${getLoyaltyPointsName(
        "bonusEarnedReminder.pointsEarned"
      )}`
    ),
    emailSubduedNote(
      `<%= bonusEarnedReminder.currentMonth.toUpperCase() %> <%- bonusEarnedReminder.customerBalance > 1 ? program.rewardUnitNamePlural.toUpperCase() : program.rewardUnitNameSingular.toUpperCase() %> BALANCE: <%- bonusEarnedReminder.customerBalance %>`
    ),
    emailBodySection(
      `You get an extra <%- bonusEarnedReminder.pointsEarned %> points for`,
      ` every <%- bonusEarnedReminder.bonusPointsOrderNumber %> order<%- bonusEarnedReminder.bonusPointsOrderNumber === 1? '': 's' %>.`,
      ` Save your <%- program.rewardUnitNamePlural %> for some of these great rewards on your next order: `
    ),
    emailLoyaltyItems("bonusEarnedReminder.loyaltyItems", shopifyData),
    emailButton("View all rewards", `https://${shopifyData.domain}/account`),
    emailQuestionSection(shopifyData)
  );
}
