import React from "react";
import { HeadingSm, BodySm, DisplayLg, BodyXs } from "../../../../components/Text";
import { Section, Row, Column } from "@react-email/components";
import { capitalizeVariableString, formatTemplateVariable } from "../../../../utils";
import { EmailCard } from "../../../../components/EmailCard";

export const TierProgressBarSection = ({
  title,
  showSubtitleCopy = true,
}: {
  title: string;
  showSubtitleCopy: boolean;
}) => {
  return (
    <EmailCard style={{ marginBottom: "30px" }}>
      <Section
        style={{
          borderTop: `8px solid ${formatTemplateVariable("loyalty.tiers.currentTier.tierColor")}`,
        }}
      >
        <Column style={{ padding: "20px" }}>
          <HeadingSm style={{ marginBottom: "20px", fontWeight: "400" }}>{title}</HeadingSm>
          <Row style={{ marginBottom: "20px" }}>
            <Column style={{ display: "inline-block", width: "50%" }}>
              <BodySm
                style={{
                  color: formatTemplateVariable("theming.colors.secondaryTextColor"),
                  paddingBottom: "10px",
                  margin: "0",
                }}
              >
                {capitalizeVariableString("program.rewardUnitNamePlural")} earned:
              </BodySm>
              <DisplayLg>{formatTemplateVariable("loyalty.customerTierBalance")}</DisplayLg>
            </Column>
            {`<% if(loyalty.tiers.nextTier){ %>`}
            <Column style={{ display: "inline-block", width: "50%", textAlign: "end" }}>
              <BodySm
                style={{
                  color: formatTemplateVariable("theming.colors.secondaryTextColor"),
                  paddingBottom: "10px",
                  margin: "0",
                }}
              >
                {capitalizeVariableString("program.rewardUnitNamePlural")} to{" "}
                {formatTemplateVariable("loyalty.tiers.nextTier.tierName")} tier:
              </BodySm>
              <DisplayLg>{`<%- Number(loyalty.tiers.nextTier.minimumPoints) - Number(loyalty.customerTierBalance) %>`}</DisplayLg>
            </Column>
            {`<% } %>`}
          </Row>
          <div
            style={{
              width: "100%",
              borderRadius: "100px",
              border: `1px solid ${formatTemplateVariable("loyalty.tiers.currentTier.tierColor")}`,
              height: "30px",
              marginBottom: "20px",
            }}
          >
            {`<% const steps = loyalty.tiers.nextTier ? Number(loyalty.tiers.nextTier.minimumPoints): 0 %>`}
            {`<% const increments = 100 / steps %>`}
            {`<% const leftProgress = loyalty.tiers.nextTier ? Number(loyalty.customerTierBalance) * increments : 100 %>`}
            <div
              style={{
                height: "30px",
                width: `${formatTemplateVariable("leftProgress")}%`,
                backgroundColor: formatTemplateVariable("loyalty.tiers.currentTier.tierColor"),
                borderRadius: "100px",
              }}
            />
          </div>
          {showSubtitleCopy ? (
            <BodyXs
              style={{
                color: formatTemplateVariable("theming.colors.secondaryTextColor"),
                margin: "0",
              }}
            >
              {`<% if(!loyalty.tiers.nextTier){ %>`}
              You&apos;re at the top tier for the year - thank you for being a valued customer!
              {`<% } else { %>`}
              Earn more points by December 31 to reach the next tier and unlock more rewards. Tiers reset yearly.
              {`<% } %>`}
            </BodyXs>
          ) : (
            <React.Fragment />
          )}
        </Column>
      </Section>
    </EmailCard>
  );
};
