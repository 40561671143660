import { isEmpty } from "lodash";

export const emailButton = (buttonText: string, link: string): string =>
  `<% if(${!isEmpty(buttonText) && !isEmpty(link)}){ %>
    <tr>
      <td class="pb-30" align="left" style="padding-bottom: 30px;">
        <!-- Button -->
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border-collapse: separate; border-radius: 50px;" bgcolor="<%- theming.colors.buttonBackgroundColor %>">
          <tr>
            <td class="btn btnp-10-20 fz-16 lh-20 c-white l-white" style="font-weight: bold; font-family:Arial, sans-serif; text-align:center; mso-line-height-rule:exactly; mso-padding-alt:10px 20px; font-size: 16px; line-height: 20px; color:<%- theming.colors.buttonTextColor %>;">
              <a href="${link}" target="_blank" class="link c-white" style="display: block; padding: 10px 20px; text-decoration:none; color:<%- theming.colors.buttonTextColor %>;">
                ${buttonText}
              </a>
            </td>
          </tr>
        </table>
        <!-- END Button -->
      </td>
    </tr>
  <% } %>`;
