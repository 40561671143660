import { isEmpty } from "lodash";
import { emailCombineText } from "../utils";

export const emailFrame = (...input: string[]): string => {
  const text = emailCombineText(input);
  return `<% if(${!isEmpty(text)}){ %>
    <tr>
      <td class="pt-31 tdn" style="border-bottom: 1px solid #ECECEC; padding-top: 31px;">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          ${text}
        </table>
      </td>
	  </tr>
  <% } %>`;
};
