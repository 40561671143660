import { IShopifyData } from "../../../../ShopifyData";
import React from "react";
import BasicCustomerEmailFrame from "../../../components/BasicCustomerEmailFrame";
import { BodyLg, HeadingLg } from "../../../components/Text";
import { formatTemplateVariable } from "../../../utils";
import { RoundedButton } from "../../../components/RoundedButton";
import { ShopHeaderWithLogo } from "../components/ShopHeaderWithLogo";
import { ShopFooterWithAddress } from "../components/ShopFooterWithAddress";
import { getLoyaltyPointsName } from "./components";
import { HaveAQuestionSection } from "../components/HaveAQuestionSection";
import { EmailTemplateCustomer, ICustomerEmailTheming } from "../../../types";
import { LoyaltyApi } from "../../../../../interfaces/loyalty/api";
import { LoyaltyPointActionsSection } from "./components/LoyaltyPointActionsSection";

export interface WelcomeEmailTemplateContextProps {
  customer: EmailTemplateCustomer;
  loyalty: {
    customerBalance: number;
    currencySymbol: string;
  };
  program: LoyaltyApi.Program.Type;
  theming: ICustomerEmailTheming;
}

/**
 * `<%- loyalty.VARIABLE %>`,
 * `<%- customer.VARIABLE %>`,
 * `<%- program.VARIABLE %>`,
 * @param loyalty.customerBalance - newly updated point balance from custRel
 * @param program.rewardUnitNamePlural -  plural reward unit name
 * @param program.rewardUnitNameSingular - singular reward unit name
 * @param program.events - point generation events, see `dummyRewards` within [`shared/entities/CustomerEmailConfig/mocks.ts`](../../../mocks.ts)
 * @param program.referralProgram.pointsInReward - points distributed after referring
 * @param program.bonus.givePoints - points distributed after hitting nth order
 * @param program.bonus.every - nth order
 */
export function LoyaltyWelcomeEmail({
  shopifyData,
  tiersEnabled = false,
}: {
  shopifyData: IShopifyData;
  tiersEnabled?: boolean;
}) {
  return (
    <BasicCustomerEmailFrame
      emailPreviewText="You earned your first points"
      emailTitle="You earned your first points"
    >
      <ShopHeaderWithLogo shopifyData={shopifyData} />
      <HeadingLg
        data-testid="loyalty-welcome-email_header"
        style={{ color: formatTemplateVariable("theming.colors.primaryTextColor") }}
      >
        {formatTemplateVariable("customer.firstName")}, you just earned your first{" "}
        {formatTemplateVariable("program.rewardUnitNamePlural")}!
      </HeadingLg>
      <BodyLg
        data-testid="loyalty-welcome-email_points-info-copy"
        style={{
          margin: 0,
          color: formatTemplateVariable("theming.colors.primaryTextColor"),
        }}
      >
        You just earned {formatTemplateVariable("loyalty.customerBalance")}{" "}
        {getLoyaltyPointsName("loyalty.customerBalance")}! Keep earning{" "}
        {formatTemplateVariable("program.rewardUnitNamePlural")} to get access to special rewards you can use on
        your next order.
      </BodyLg>
      <LoyaltyPointActionsSection tiersEnabled={tiersEnabled} />
      <RoundedButton
        data-testid="loyalty-welcome-email_view-rewards-button"
        href={`https://${shopifyData.domain}/account`}
        style={{
          width: "540px",
          marginBottom: "30px",
          background: formatTemplateVariable("theming.colors.buttonBackgroundColor"),
          color: formatTemplateVariable("theming.colors.buttonTextColor"),
        }}
      >
        View all rewards
      </RoundedButton>
      <HaveAQuestionSection shopifyData={shopifyData} />
      <ShopFooterWithAddress shopifyData={shopifyData} />
    </BasicCustomerEmailFrame>
  );
}
