import React from "react";
import { Column } from "@react-email/components";
import { EmailLoyaltyIcon } from "../../components/EmailLoyaltyIcon";
import { formatTemplateVariable } from "../../../../utils";
import { BodySm, BodyXs } from "../../../../components/Text";
import { EmailIcons } from "../../../constants";
import { kebabCase } from "lodash";

export const EarnPointsAction = ({
  icon,
  primaryText,
  secondaryText,
  alt,
}: {
  icon: keyof typeof EmailIcons;
  primaryText: string;
  secondaryText: string;
  alt?: string;
}) => {
  return (
    <React.Fragment>
      <Column
        data-testid={`loyalty-welcome-email_${kebabCase(primaryText.toLocaleLowerCase())}-action-icon`}
        width="40px"
      >
        <EmailLoyaltyIcon alt={alt ?? `${primaryText} icon`} src={EmailIcons[icon]} />
      </Column>
      <Column data-testid={`loyalty-welcome-email_${kebabCase(primaryText.toLocaleLowerCase())}-action-copy`}>
        <BodySm
          style={{
            color: formatTemplateVariable("theming.colors.primaryTextColor"),
            margin: 0,
            padding: 0,
          }}
        >
          {primaryText}
        </BodySm>
        <BodyXs style={{ color: formatTemplateVariable("theming.colors.secondaryTextColor") }}>
          {secondaryText}
        </BodyXs>
      </Column>
    </React.Fragment>
  );
};
