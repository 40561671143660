export const SMARTRR_COLORS = {
  // Branded Colors
  duskyPurple: "#3B1E33",
  neutralGrey: "#ECECEC",
  lighterLavender: "#EFEEFB",
  cyberLavender: "#E6E6FA",
  brighterBlue: "#D4E6E9",
  lighterChartreuse: "#EBFAA5",
  chartreuse: "#CEF31E",
  darkBlue: "#171435",
  // Standard / Neutral colors
  black: "#000000",
  white: "#FFFFFF",
  darkGrey: "#2E2E2E",
  lightGrey: "#EDEDED",
  subduedGrey: "#7A7A7A",
  success: "#0C9500",
  failure: "#FF0000",
};

export default SMARTRR_COLORS;
