import React from "react";
import { Head, Preview } from "@react-email/components";

interface EmailHeadProps {
  emailTitle: string;
  emailPreviewText: string;
}

export const EmailHead = ({ emailPreviewText, emailTitle }: EmailHeadProps) => {
  //! Do not place styles in the email head unless they are global, styles should be used inline
  return (
    <React.Fragment>
      <Head>
        <title>{emailTitle}</title>
        <Preview>{emailPreviewText}</Preview>
        <meta httpEquiv="Content-type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="format-detection" content="date=no" />
        <meta name="format-detection" content="address=no" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="x-apple-disable-message-reformatting" />
      </Head>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            a[href] { 
              text-decoration: underline;
              color: inherit;
            }

            @media screen and (max-width: 600px){
              #customer-email-frame{
                padding: 20px;
              }
            }
              
            @media screen and (min-width: 601px){
              #customer-email-frame{
                padding: 40px 100px
              }
            }
          `,
        }}
      />
    </React.Fragment>
  );
};
