import React from "react";
import { Row, Section, Column } from "@react-email/components";
import { formatTemplateVariable } from "../../../../utils";
import SMARTRR_COLORS from "@smartrr/shared/branding/colors";
import { getLoyaltyPointsName } from "../components";
import { EarnPointsAction } from "./EarnPointsAction";
import { TierProgressBarSection } from "./TierProgressBarSection";

export const LoyaltyPointActionsSection = ({ tiersEnabled = false }: { tiersEnabled: boolean }) => {
  return (
    <Section
      style={{
        margin: "30px 0",
        padding: "30px 30px 15px",
        borderRadius: "6px",
        backgroundColor: SMARTRR_COLORS.neutralGrey,
        borderCollapse: "separate",
      }}
    >
      <Section>
        {tiersEnabled ? (
          <TierProgressBarSection
            title={`You&apos;re at ${formatTemplateVariable("loyalty.tiers.currentTier.tierName")} tier`}
            showSubtitleCopy
          />
        ) : (
          <React.Fragment />
        )}
        <Row>
          {` <% if ((program.events && program.events.length)){ %>`}
          {`<% program.events.forEach((event) => { %> `}
          {/* display: inline-block is what helps the cells to wrap between rows, do not remove*/}
          <Column style={{ width: "50%", display: "inline-block", marginBottom: "15px" }}>
            <Section>
              {` <% if (event.type === "BILLING_SUCCESS"){ %>`}
              <EarnPointsAction
                icon="CART_ICON_URL"
                alt="Points per spend icon"
                primaryText="Orders"
                secondaryText={`${formatTemplateVariable("event.pointsEarned")} ${getLoyaltyPointsName("event.pointsEarned")} per ${formatTemplateVariable("loyalty.currencySymbol")}1 spend`}
              />
              {`<% } %> `}
              {` <% if (event.type === "ACCOUNT_CREATED"){ %>`}
              <EarnPointsAction
                icon="USER_ICON_URL"
                alt="Points on account creation icon"
                primaryText="Creating an account"
                secondaryText={`${formatTemplateVariable("event.pointsEarned")} ${getLoyaltyPointsName("event.pointsEarned")}`}
              />
              {`<% } %> `}
            </Section>
          </Column>
          {`  <% }) %>`}
          {`<% } %> `}
          {` <% if ((program.referralProgram && program.referralProgram.pointsInReward)){ %>`}
          <Column style={{ width: "50%", display: "inline-block", marginBottom: "15px" }}>
            <Section>
              <EarnPointsAction
                icon="STAR_ICON_URL"
                alt="Referral icon"
                primaryText="Referring a friend"
                secondaryText={`${formatTemplateVariable("program.referralProgram.pointsInReward")} ${getLoyaltyPointsName("program.referralProgram.pointsInReward")}`}
              />
            </Section>
          </Column>
          {`<% } %> `}
          {` <% if ((program.bonus && (program.bonus.every && program.bonus.givePoints))){ %>`}
          <Column style={{ width: "50%", display: "inline-block", marginBottom: "15px" }}>
            <Section>
              <EarnPointsAction
                icon="SPARKLE_ICON_URL"
                alt="Bonus earn icon"
                primaryText="Bonus earn"
                secondaryText={`${formatTemplateVariable("program.bonus.givePoints")} ${getLoyaltyPointsName("program.bonus.givePoints")} every <%- program.bonus.every > 1 ? program.bonus.every : "" %><%- program.bonus.every > 1 ? " orders" : "order" %>
              `}
              />
            </Section>
          </Column>
          {`<% } %> `}
        </Row>
      </Section>
    </Section>
  );
};
