import { isEmpty } from "lodash";

export const emailHeader = (text: string): string =>
  `<% if(${!isEmpty(text)}){ %>
    <tr>
      <td class="title fz-42 lh-44 pb-29" style="letter-spacing: -1px; font-weight: bold; color:<%- theming.colors.primaryTextColor %>; font-family:Arial, sans-serif; text-align:left; mso-line-height-rule:exactly; font-size: 42px; line-height: 44px; padding-bottom: 29px;">
        ${text}
      </td>
    </tr>
  <% } %>`;
