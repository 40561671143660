const TROPHY_ICON_URL = "https://8974553.fs1.hubspotusercontent-na1.net/hubfs/8974553/smartrr-trophy.png";

const SPARKLE_ICON_URL =
  "https://8974553.fs1.hubspotusercontent-na1.net/hubfs/8974553/Product/smartrr-sparkle.png";

const USER_ICON_URL = "https://8974553.fs1.hubspotusercontent-na1.net/hubfs/8974553/Product/smartrr-user.png";

const STAR_ICON_URL = "https://8974553.fs1.hubspotusercontent-na1.net/hubfs/8974553/Product/smartrr-star.png";

const CART_ICON_URL = "https://8974553.fs1.hubspotusercontent-na1.net/hubfs/8974553/Product/smartrr-cart.png";

export const EmailIcons = { TROPHY_ICON_URL, SPARKLE_ICON_URL, USER_ICON_URL, STAR_ICON_URL, CART_ICON_URL };
