import { ICustomerEmailConfigForm, ICustomerEmailTheming, ICustomerEmailThemingColors } from "./types";
import { z } from "zod";

interface BaseOrmColumns {
  id: string;
  deletedAt: string | null;
  createdDate: string;
  updatedDate: string;
}

// Needed for accepting empty strings when no email is provided int he BCC address field
const emptyStringEmailSchema = ({ message }: { message: string }) =>
  z.union([z.literal(""), z.string().email(message)]);

// Checking for key
type ZodRecord<T> = Record<keyof T, unknown>;

export namespace NotificationEmails {
  export const emailThemingSchema = z.object({
    logo: z.string(),
    // Logo width is set in the FE as a number but is returned from our DB as a string. This type difference doesn't affect the FE so coercing to a number to make it more flexible.
    logoWidth: z.union([z.string(), z.number()]),
    useHeaderAndFooter: z.boolean(),
    useStyling: z.boolean(),
    colors: z.object({
      backgroundColor: z.string(),
      primaryTextColor: z.string(),
      secondaryTextColor: z.string(),
      buttonBackgroundColor: z.string(),
      buttonTextColor: z.string(),
    } satisfies ZodRecord<ICustomerEmailThemingColors>),
  } satisfies ZodRecord<ICustomerEmailTheming>);

  export const baseSchema = z.object({
    sendSubscriptionCreateSignupEmail: z.boolean(),
    sendSubscriptionCreateSignupEmailBcc: z.boolean().nullable(),
    subscriptionCreateSignupEmailSubject: z.string(),
    subscriptionCreateSignupEmailBody: z.string(),
    subscriptionCreateSignupEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Subscription created - New customer email",
    }),

    sendSubscriptionCreateEmail: z.boolean(),
    sendSubscriptionCreateEmailBcc: z.boolean().nullable(),
    subscriptionCreateEmailSubject: z.string(),
    subscriptionCreateEmailBody: z.string(),
    subscriptionCreateEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Subscription created - Existing customer email",
    }),

    sendCancelEmail: z.boolean(),
    sendCancelEmailBcc: z.boolean().nullable(),
    cancelEmailSubject: z.string(),
    cancelEmailBody: z.string(),
    cancelEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Subscription canceled email",
    }),

    sendUpcomingOrderEmail: z.boolean(),
    sendUpcomingOrderEmailBcc: z.boolean().nullable(),
    upcomingOrderEmailSubject: z.string(),
    upcomingOrderEmailBody: z.string(),
    upcomingOrderEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Upcoming subscription email",
    }),

    upcomingOrderEmailDeliveryDelay: z.union([z.string(), z.number()]),

    sendExpiredPaymentMethodEmail: z.boolean(),
    sendExpiredPaymentMethodEmailBcc: z.boolean().nullable(),
    expiredPaymentMethodEmailSubject: z.string(),
    expiredPaymentMethodEmailBody: z.string(),
    expiredPaymentMethodEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Payment expiration notice email",
    }),

    sendUpdatePaymentEmailOnFailedPayment: z.boolean(),

    sendUpcomingGiftEmail: z.boolean(),
    sendUpcomingGiftEmailBcc: z.boolean().nullable(),
    upcomingGiftEmailSubject: z.string(),
    upcomingGiftEmailBody: z.string(),
    upcomingGiftEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Giftee subscription shipped email",
    }),

    sendGiftIsBeingSentEmail: z.boolean(),
    sendGiftIsBeingSentEmailBcc: z.boolean().nullable(),
    giftIsBeingSentEmailSubject: z.string(),
    giftIsBeingSentEmailBody: z.string(),
    giftIsBeingSentEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Gifted subscription email",
    }),

    sendGifterUpcomingGiftEmail: z.boolean(),
    gifterUpcomingGiftEmailSubject: z.string(),
    gifterUpcomingGiftEmailBody: z.string(),
    sendGifterUpcomingGiftEmailBcc: z.boolean(),
    gifterUpcomingGiftEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Gifter subscription shipped email",
    }),

    itemOutOfStockEmail: z.boolean(),
    itemOutOfStockEmailBcc: z.boolean().nullable(),
    itemOutOfStockEmailSubject: z.string(),
    itemOutOfStockEmailBody: z.string(),
    itemOutOfStockEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Item out of stock or deleted email",
    }),

    sendSubscriptionPausedEmail: z.boolean(),
    sendSubscriptionPausedEmailBcc: z.boolean().nullable(),
    subscriptionPausedEmailSubject: z.string(),
    subscriptionPausedEmailBody: z.string(),
    subscriptionPausedEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Subscription paused email",
    }),

    sendSubscriptionAutoResumeEmail: z.boolean(),
    sendSubscriptionAutoResumeEmailBcc: z.boolean().nullable(),
    subscriptionAutoResumeEmailSubject: z.string(),
    subscriptionAutoResumeEmailBody: z.string(),
    subscriptionAutoResumeEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Subscription auto-resume reminder email",
    }),

    sendSubscriptionResumedEmail: z.boolean(),
    sendSubscriptionResumedEmailBcc: z.boolean().nullable(),
    subscriptionResumedSubject: z.string(),
    subscriptionResumedEmailBody: z.string(),
    subscriptionResumedEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Subscription reactivated email",
    }),

    emailHeader: z.string(),
    emailFooter: z.string(),
    emailTheming: emailThemingSchema,

    isArpuEnabled: z.boolean(),

    oneTimeCodeEmailSubject: z.string(),
    oneTimeCodeEmailBody: z.string(),
    magicLinkEmailSubject: z.string(),
    magicLinkEmailBody: z.string(),

    sendSuccessfulReferralEmail: z.boolean(),
    sendSuccessfulReferralEmailBcc: z.boolean().nullable(),
    successfulReferralEmailSubject: z.string(),
    successfulReferralEmailBody: z.string(),
    successfulReferralEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Successful referral email",
    }),

    sendPaymentFailureEmail: z.boolean(),
    sendPaymentFailureEmailBcc: z.boolean().nullable(),
    paymentFailureEmailSubject: z.string(),
    paymentFailureEmailBody: z.string(),
    paymentFailureEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Payment failed email",
    }),

    sendAccountCreationReminderEmail: z.boolean(),
    sendAccountCreationReminderEmailBcc: z.boolean().nullable(),
    accountCreationReminderEmailSubject: z.string(),
    accountCreationReminderEmailBody: z.string(),
    accountCreationReminderEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Account creation reminder email",
    }),

    sendBonusEarnedEmail: z.boolean(),
    sendBonusEarnedEmailBcc: z.boolean().nullable(),
    bonusEarnedEmailSubject: z.string(),
    bonusEarnedEmailBody: z.string(),
    bonusEarnedEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Bonus point earn email",
    }),

    sendOtpCodeRedeemedEmail: z.boolean(),
    sendOtpCodeRedeemedEmailBcc: z.boolean().nullable(),
    otpCodeRedeemedEmailSubject: z.string(),
    otpCodeRedeemedEmailBody: z.string(),
    otpCodeRedeemedEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Redemption Code email",
    }),

    sendRewardReminderEmail: z.boolean(),
    rewardReminderEmailSubject: z.string(),
    rewardReminderEmailBody: z.string(),
    sendRewardReminderEmailBcc: z.boolean(),
    rewardReminderEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Rewards reminder email",
    }),

    sendRewardOutOfStockEmail: z.boolean(),
    sendRewardOutOfStockEmailBcc: z.boolean().nullable(),
    rewardOutOfStockEmailSubject: z.string(),
    rewardOutOfStockEmailBody: z.string(),
    rewardOutOfStockEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Reward item out of stock or deleted email",
    }),

    sendLoyaltyCurrencyReminder: z.boolean(),
    loyaltyCurrencyReminderEmailBody: z.string(),
    loyaltyCurrencyReminderEmailSubject: z.string(),

    sendMigrationSummaryEmail: z.boolean(),
    migrationSummaryEmailBody: z.string(),
    migrationSummaryEmailSubject: z.string(),

    sendInsightsEmail: z.boolean(),

    sendLoyaltyWelcomeEmail: z.boolean(),
    sendLoyaltyWelcomeEmailBcc: z.boolean().nullable(),
    loyaltyWelcomeEmailSubject: z.string(),
    loyaltyWelcomeEmailBody: z.string(),
    loyaltyWelcomeEmailBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Loyalty welcome email",
    }),

    sendLoyaltyTiersWelcomeEmail: z.boolean(),
    sendLoyaltyTiersWelcomeEmailBcc: z.boolean().nullable(),
    loyaltyTiersWelcomeEmailSubject: z.string(),
    loyaltyTiersWelcomeEmailBody: z.string(),
    loyaltyTiersWelcomeBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Tiered loyalty welcome",
    }),

    sendLoyaltyTierAdvancementEmail: z.boolean(),
    sendLoyaltyTierAdvancementEmailBcc: z.boolean().nullable(),
    loyaltyTierAdvancementEmailSubject: z.string(),
    loyaltyTierAdvancementEmailBody: z.string(),
    loyaltyTierAdvancementBccAddress: emptyStringEmailSchema({
      message: "Invalid BCC email provided for: Tier advancement email",
    }),
  } satisfies ZodRecord<ICustomerEmailConfigForm>);

  const apiSchemaAdditions = z.object({
    id: z.string().uuid(),
    createdDate: z.string(),
    deletedAt: z.string().nullable(),
    updatedDate: z.string(),
  } satisfies ZodRecord<BaseOrmColumns>);

  const apiResponseSchemaUnion = z.union([baseSchema, apiSchemaAdditions]);

  export type Type = z.infer<typeof baseSchema>;
  export type ApiResponseType = z.infer<typeof apiResponseSchemaUnion>;
}
