import { Text, Heading as ReactEmailHeading, HeadingProps } from "@react-email/components";
import React from "react";
import SMARTRR_COLORS from "@smartrr/shared/branding/colors";
import { formatTemplateVariable } from "../utils";

// Default styles (color, fontSize, etc...) should not be pulled from context
export const HeadingSm = ({
  children,
  style,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement> & HeadingProps) => (
  <ReactEmailHeading
    as="h1"
    style={{
      fontSize: "20px",
      fontFamily: "Arial, sans-serif",
      color: SMARTRR_COLORS.duskyPurple,
      fontWeight: "bold",
      fontStyle: "normal",
      letterSpacing: "-0.2px",
      lineHeight: "150%",
      ...style,
    }}
    {...props}
  >
    {children}
  </ReactEmailHeading>
);

export const HeadingLg = ({
  children,
  style,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement> & HeadingProps) => (
  <ReactEmailHeading
    as="h1"
    style={{
      fontSize: "42px",
      fontFamily: "Arial, sans-serif",
      color: SMARTRR_COLORS.duskyPurple,
      fontWeight: "bold",
      fontStyle: "normal",
      letterSpacing: "-1px",
      lineHeight: "44px",
      padding: "30px 0",
      margin: "0px",
      ...style,
    }}
    {...props}
  >
    {children}
  </ReactEmailHeading>
);

export const DisplayLg = ({
  children,
  style,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement> & HeadingProps) => (
  <ReactEmailHeading
    as="h3"
    style={{
      fontSize: "32px",
      fontFamily: "Arial, sans-serif",
      color: formatTemplateVariable("theming.colors.primaryTextColor"),
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "100%",
      margin: "0px",
      ...style,
    }}
    {...props}
  >
    {children}
  </ReactEmailHeading>
);

export const BodyLg = ({ children, style, ...props }: React.HTMLAttributes<HTMLParagraphElement>) => (
  <Text
    style={{
      fontSize: "16px",
      color: SMARTRR_COLORS.duskyPurple,
      fontStyle: "normal",
      fontWeight: "400",
      letterSpacing: "-0.16px",
      lineHeight: "150%",
      margin: "0",
      ...style,
    }}
    {...props}
  >
    {children}
  </Text>
);

export const BodySm = ({ children, style, ...props }: React.HTMLAttributes<HTMLParagraphElement>) => (
  <Text
    style={{
      color: SMARTRR_COLORS.duskyPurple,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      letterSpacing: "-0.14px",
      lineHeight: "150%",
      paddingBottom: "5px",
      ...style,
    }}
    {...props}
  >
    {children}
  </Text>
);

export const BodyXs = ({ children, style, ...props }: React.HTMLAttributes<HTMLParagraphElement>) => (
  <Text
    style={{
      color: SMARTRR_COLORS.subduedGrey,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16px",
      paddingBottom: "5px",
      margin: "0px",
      ...style,
    }}
    {...props}
  >
    {children}
  </Text>
);
