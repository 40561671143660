import { EmailTemplateSubscriptionLine } from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";

export const getDummyLineItems = (purchasables: IPurchasable[]): EmailTemplateSubscriptionLine[] =>
  purchasables.slice(0, 3).map(p => {
    const vnt = p.vnts?.find(vnt => !!vnt.purchasableVariantImages)!;
    return {
      id: p.id,
      basePrice: "",
      isAddOn: false,
      priceAfterDiscounts: "",
      quantity: Math.ceil(Math.random() * 4),
      product: {
        id: p.id,
        name: p.purchasableName,
      },
      variant: {
        id: vnt?.id,
        name: vnt?.purchasableVariantName!,
        image: vnt?.purchasableVariantImages?.[0] ?? p.purchasableImages?.[0],
      },
    };
  });
