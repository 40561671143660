import React from "react";
import { Img } from "@react-email/components";
import SMARTRR_COLORS from "../../../../../branding/colors";

export const EmailLoyaltyIcon = ({
  src,
  alt,
  options = {},
}: {
  src: string;
  alt: string;
  options?: {
    width?: number;
    height?: number;
  };
}) => {
  return (
    <Img
      alt={alt}
      src={src}
      style={{
        width: options.width ?? 20,
        height: options.height ?? 20,
        borderRadius: "16px",
        padding: "5px",
        backgroundColor: SMARTRR_COLORS.white,
      }}
    />
  );
};
