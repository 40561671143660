import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailProductData } from "../../../components/emailProductData";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubHeader } from "../../../components/emailSubHeader";
import { emailSubduedNote } from "../../../components/emailSubduedNote";

// Gifted subscription - Scheduled (Gifter)
export function createDefaultGiftIsBeingSentEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(`Your gift is scheduled`),
    emailSubHeader("<%- to.firstName %> WILL RECEIVE"),
    emailProductData(),
    emailBodySection(
      "Confirming your gift is scheduled to processed on <%- shippedOn %>. <% if( note ){ %> Your gift message will be: <% } %>"
    ),
    `<% if( note ){ %>
      ${emailSubduedNote("&ldquo;<%- note %>&rdquo;")},
    <% } %>`,
    emailButton("View gifted order", `https://${shopifyData.domain}/account`),
    emailQuestionSection(shopifyData)
  );
}
