import { useEffect, useMemo } from "react";

import { NotificationEmails, EmailSectionsEnum } from "@smartrr/shared/entities/CustomerEmailConfig";
import { coalesceToDefaultConfig, coalesceToTrue } from "@smartrr/shared/entities/CustomerEmailConfig/utils";
import {
  EmailRecipientType,
  ICustomerEmailConfig,
  IEmailPreview,
} from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { cloneDeep } from "lodash";
import { useDefaultEmailConfig } from "..";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import retry from "async-retry";
import { noawait } from "@smartrr/shared/utils/noawait";
import { EmailNotificationAccessStore } from "@vendor-app/app/_state/zustand/EmailNotificationsStore";

export type CustomerEmailConfigOptions = Record<NotificationEmails, IEmailPreview>;

const useGenerateEmailConfigOptions = ({
  defaults,
  emailRecipient = "customer",
  allowShopifyEmails = true,
}: {
  defaults: boolean; // defaults are used within the template editor
  emailRecipient?: EmailRecipientType;
  allowShopifyEmails?: boolean;
}): CustomerEmailConfigOptions => {
  const { emailConfigs } = EmailNotificationAccessStore.useEmailConfigs();
  const actions = LoyaltyStoreAccess.useActions();
  const defaultEmailConfig = useDefaultEmailConfig();
  const { notificationEmailRefactor } = useSmartrrFlags();

  const getCoalescedValue = (configValue: keyof ICustomerEmailConfig) =>
    coalesceToDefaultConfig(defaultEmailConfig, emailConfigs, configValue, defaults) as string;

  const getCoalescedBoolean = (configValue: keyof ICustomerEmailConfig) =>
    coalesceToTrue(emailConfigs, configValue, defaults);

  // Fetching loyalty specific information
  const isTiersEnabled = LoyaltyStoreAccess.useIsTiersEnabled();
  const rewards = LoyaltyStoreAccess.useRewards();

  useEffect(() => {
    if (!rewards) {
      noawait(async () => await retry(() => actions.initialize(), { retries: 3 }));
    }
  }, [rewards]);

  return useMemo(() => {
    const configs: CustomerEmailConfigOptions = {
      [NotificationEmails.SUBSCRIPTION_CREATE]: {
        enabled: getCoalescedBoolean("sendSubscriptionCreateEmail"),
        emailSubject: getCoalescedValue("subscriptionCreateEmailSubject"),
        emailBody: getCoalescedValue("subscriptionCreateEmailBody"),
        title: "Subscription created - Existing customer",
        description: "Sent when a new subscription order is created for a customer who has a customer account.",
        section: EmailSectionsEnum.NEW_SUBSCRIPTION,
        conditional: false,
        emailRecipient: "customer",
        disableHeaderAndFooter: notificationEmailRefactor,
        configValues: {
          toggle: "sendSubscriptionCreateEmail",
          subject: "subscriptionCreateEmailSubject",
          body: "subscriptionCreateEmailBody",
          bccAddress: "subscriptionCreateEmailBccAddress",
          sendBccAddress: "sendSubscriptionCreateEmailBcc",
        },
      },

      [NotificationEmails.SUBSCRIPTION_CREATE_SIGNUP]: {
        enabled: getCoalescedBoolean("sendSubscriptionCreateSignupEmail"),
        emailSubject: getCoalescedValue("subscriptionCreateSignupEmailSubject"),
        emailBody: getCoalescedValue("subscriptionCreateSignupEmailBody"),
        title: "Subscription created - New customer",
        description:
          "Sent when a new subscription order is created for a customer who does not already have a customer account with your store.",
        section: EmailSectionsEnum.NEW_SUBSCRIPTION,
        conditional: false,
        disableHeaderAndFooter: false,
        emailRecipient: "customer",
        configValues: {
          toggle: "sendSubscriptionCreateSignupEmail",
          subject: "subscriptionCreateSignupEmailSubject",
          body: "subscriptionCreateSignupEmailBody",
          bccAddress: "subscriptionCreateSignupEmailBccAddress",
          sendBccAddress: "sendSubscriptionCreateSignupEmailBcc",
        },
      },

      [NotificationEmails.SUBSCRIPTION_UPCOMING_ORDER]: {
        enabled: getCoalescedBoolean("sendUpcomingOrderEmail"),
        emailSubject: getCoalescedValue("upcomingOrderEmailSubject"),
        emailBody: getCoalescedValue("upcomingOrderEmailBody"),
        title: "Upcoming subscription",
        description: "Email sent in advance of a recurring subscription order being placed.",
        section: EmailSectionsEnum.SUBSCRIPTION_MANAGEMENT,
        conditional: false,
        disableHeaderAndFooter: false,
        emailRecipient: "customer",
        configValues: {
          toggle: "sendUpcomingOrderEmail",
          subject: "upcomingOrderEmailSubject",
          body: "upcomingOrderEmailBody",
          bccAddress: "upcomingOrderEmailBccAddress",
          sendBccAddress: "sendUpcomingOrderEmailBcc",
        },
      },

      [NotificationEmails.SUBSCRIPTION_AUTO_RESUME]: {
        enabled: getCoalescedBoolean("sendSubscriptionAutoResumeEmail"),
        emailSubject: getCoalescedValue("subscriptionAutoResumeEmailSubject"),
        emailBody: getCoalescedValue("subscriptionAutoResumeEmailBody"),
        title: "Subscription auto-resume reminder",
        description: "Sent 7 days before a paused subscription is set to auto-resume.",
        section: EmailSectionsEnum.SUBSCRIPTION_MANAGEMENT,
        conditional: false,
        emailRecipient: "customer",
        disableHeaderAndFooter: false,
        configValues: {
          toggle: "sendSubscriptionAutoResumeEmail",
          subject: "subscriptionAutoResumeEmailSubject",
          body: "subscriptionAutoResumeEmailBody",
          bccAddress: "subscriptionAutoResumeEmailBccAddress",
          sendBccAddress: "sendSubscriptionAutoResumeEmailBcc",
        },
      },

      [NotificationEmails.PAYMENT_EXPIRATION_NOTICE]: {
        enabled: getCoalescedBoolean("sendExpiredPaymentMethodEmail"),
        emailSubject: getCoalescedValue("expiredPaymentMethodEmailSubject"),
        emailBody: getCoalescedValue("expiredPaymentMethodEmailBody"),
        title: "Payment expiration notice",
        description: "Sent instead if credit card used for upcoming subscription is set to expire.",
        section: EmailSectionsEnum.SUBSCRIPTION_MANAGEMENT,
        conditional: true,
        disableHeaderAndFooter: false,
        emailRecipient: "customer",
        configValues: {
          toggle: "sendExpiredPaymentMethodEmail",
          subject: "expiredPaymentMethodEmailSubject",
          body: "expiredPaymentMethodEmailBody",
          bccAddress: "expiredPaymentMethodEmailBccAddress",
          sendBccAddress: "sendExpiredPaymentMethodEmailBcc",
        },
      },

      [NotificationEmails.SUBSCRIPTION_FAILED_PAYMENT]: {
        enabled: getCoalescedBoolean("sendPaymentFailureEmail"),
        emailSubject: getCoalescedValue("paymentFailureEmailSubject"),
        emailBody: getCoalescedValue("paymentFailureEmailBody"),
        title: "Payment failed",
        description: "Sent when there is a payment-related subscription error.",
        section: EmailSectionsEnum.SUBSCRIPTION_MANAGEMENT,
        conditional: false,
        emailRecipient: "customer",
        disableHeaderAndFooter: false,
        configValues: {
          toggle: "sendPaymentFailureEmail",
          subject: "paymentFailureEmailSubject",
          body: "paymentFailureEmailBody",
          bccAddress: "paymentFailureEmailBccAddress",
          sendBccAddress: "sendPaymentFailureEmailBcc",
        },
      },

      [NotificationEmails.ITEM_OUT_OF_STOCK_CHECK]: {
        enabled: getCoalescedBoolean("itemOutOfStockEmail"),
        emailSubject: getCoalescedValue("itemOutOfStockEmailSubject"),
        emailBody: getCoalescedValue("itemOutOfStockEmailBody"),
        title: "Item out of stock or deleted",
        description:
          "Sent instead when an item in a customer's next subscription order has gone out of stock or has been deleted in Shopify.",
        section: EmailSectionsEnum.SUBSCRIPTION_MANAGEMENT,
        conditional: false,
        emailRecipient: "customer",
        disableHeaderAndFooter: false,
        configValues: {
          toggle: "itemOutOfStockEmail",
          subject: "itemOutOfStockEmailSubject",
          body: "itemOutOfStockEmailBody",
          bccAddress: "itemOutOfStockEmailBccAddress",
          sendBccAddress: "itemOutOfStockEmailBcc", // TODO: Change to sendItemOutOfStockEmailBcc
        },
      },
      [NotificationEmails.SUBSCRIPTION_CANCEL]: {
        enabled: getCoalescedBoolean("sendCancelEmail"),
        emailSubject: getCoalescedValue("cancelEmailSubject"),
        emailBody: getCoalescedValue("cancelEmailBody"),
        title: "Subscription canceled",
        description: "Sent when a subscription order has been canceled.",
        section: EmailSectionsEnum.SUBSCRIPTION_MANAGEMENT,
        conditional: false,
        disableHeaderAndFooter: false,
        emailRecipient: "customer",
        configValues: {
          toggle: "sendCancelEmail",
          subject: "cancelEmailSubject",
          body: "cancelEmailBody",
          bccAddress: "cancelEmailBccAddress",
          sendBccAddress: "sendCancelEmailBcc",
        },
      },

      [NotificationEmails.SUBSCRIPTION_PAUSE]: {
        enabled: getCoalescedBoolean("sendSubscriptionPausedEmail"),
        emailSubject: getCoalescedValue("subscriptionPausedEmailSubject"),
        emailBody: getCoalescedValue("subscriptionPausedEmailBody"),
        title: "Subscription paused",
        description:
          "Sent when a subscription order has been paused and notifies them of their auto-resume date.",
        section: EmailSectionsEnum.SUBSCRIPTION_MANAGEMENT,
        conditional: false,
        disableHeaderAndFooter: false,
        emailRecipient: "customer",
        configValues: {
          toggle: "sendSubscriptionPausedEmail",
          subject: "subscriptionPausedEmailSubject",
          body: "subscriptionPausedEmailBody",
          bccAddress: "subscriptionPausedEmailBccAddress",
          sendBccAddress: "sendSubscriptionPausedEmailBcc",
        },
      },

      [NotificationEmails.SUBSCRIPTION_ACTIVATE]: {
        enabled: getCoalescedBoolean("sendSubscriptionResumedEmail"),
        emailSubject: getCoalescedValue("subscriptionResumedSubject"),
        emailBody: getCoalescedValue("subscriptionResumedEmailBody"),
        title: "Subscription reactivated",
        description: "Sent when a subscription order has been reactivated.",
        section: EmailSectionsEnum.SUBSCRIPTION_MANAGEMENT,
        conditional: false,
        disableHeaderAndFooter: false,
        emailRecipient: "customer",
        configValues: {
          toggle: "sendSubscriptionResumedEmail",
          subject: "subscriptionResumedSubject",
          body: "subscriptionResumedEmailBody",
          bccAddress: "subscriptionResumedEmailBccAddress",
          sendBccAddress: "sendSubscriptionResumedEmailBcc",
        },
      },

      [NotificationEmails.SUBSCRIPTION_GIFT_CREATE]: {
        enabled: getCoalescedBoolean("sendGiftIsBeingSentEmail"),
        emailSubject: getCoalescedValue("giftIsBeingSentEmailSubject"),
        emailBody: getCoalescedValue("giftIsBeingSentEmailBody"),
        title: "Gifted subscription",
        description: "Sent to gifter as confirmation that a subscription gift has been scheduled.",
        section: EmailSectionsEnum.GIFTED_SUBSCRIPTIONS,
        conditional: false,
        emailRecipient: "customer",
        disableHeaderAndFooter: false,
        configValues: {
          toggle: "sendGiftIsBeingSentEmail",
          subject: "giftIsBeingSentEmailSubject",
          body: "giftIsBeingSentEmailBody",
          bccAddress: "giftIsBeingSentEmailBccAddress",
          sendBccAddress: "sendGiftIsBeingSentEmailBcc",
        },
      },

      [NotificationEmails.SUBSCRIPTION_GIFT_GIFTER]: {
        enabled: getCoalescedBoolean("sendGifterUpcomingGiftEmail"),
        emailSubject: getCoalescedValue("gifterUpcomingGiftEmailSubject"),
        emailBody: getCoalescedValue("gifterUpcomingGiftEmailBody"),
        title: "Gifter subscription shipped",
        description: "Sent to gifter when a gifted subscription order has been shipped.",
        section: EmailSectionsEnum.GIFTED_SUBSCRIPTIONS,
        conditional: false,
        disableHeaderAndFooter: false,
        emailRecipient: "customer",
        configValues: {
          toggle: "sendGifterUpcomingGiftEmail",
          subject: "gifterUpcomingGiftEmailSubject",
          body: "gifterUpcomingGiftEmailBody",
          bccAddress: "gifterUpcomingGiftEmailBccAddress",
          sendBccAddress: "sendGifterUpcomingGiftEmailBcc",
        },
      },

      [NotificationEmails.SUBSCRIPTION_GIFT_GIFTEE]: {
        enabled: getCoalescedBoolean("sendUpcomingGiftEmail"),
        emailSubject: getCoalescedValue("upcomingGiftEmailSubject"),
        emailBody: getCoalescedValue("upcomingGiftEmailBody"),
        title: "Giftee subscription shipped",
        description:
          "Sent to the giftee when a gifted subscription order has been shipped along with gift message.",
        section: EmailSectionsEnum.GIFTED_SUBSCRIPTIONS,
        conditional: false,
        disableHeaderAndFooter: false,
        emailRecipient: "customer",
        configValues: {
          toggle: "sendUpcomingGiftEmail",
          subject: "upcomingGiftEmailSubject",
          body: "upcomingGiftEmailBody",
          bccAddress: "upcomingGiftEmailBccAddress",
          sendBccAddress: "sendUpcomingGiftEmailBcc",
        },
      },

      [NotificationEmails.SHOPIFY_FAILED_PAYMENT]: {
        enabled: true,
        title: "Payment failed - Shopify",
        description:
          "Sent when a payment method for a subscription fails, prompting customer to update payment method.",
        section: EmailSectionsEnum.SENT_FROM_SHOPIFY,
        disableHeaderAndFooter: false,
        conditional: false,
        hideToggle: true,
        emailRecipient: "customer",
      },

      [NotificationEmails.ONE_TIME_CODE]: {
        title: "One-time code login",
        description: "Provides a one-time code for a customer to log into their account.",
        section: EmailSectionsEnum.PASSWORDLESS_LOGIN,
        emailSubject: getCoalescedValue("oneTimeCodeEmailSubject"),
        emailBody: getCoalescedValue("oneTimeCodeEmailBody"),
        conditional: false,
        disableHeaderAndFooter: false,
        hideToggle: true,
        emailRecipient: "customer",
        configValues: {
          subject: "oneTimeCodeEmailSubject",
          body: "oneTimeCodeEmailBody",
        },
      },

      [NotificationEmails.MAGIC_LINK]: {
        title: "Magic link login",
        description: "Provides a magic link for a customer to log into their account.",
        section: EmailSectionsEnum.PASSWORDLESS_LOGIN,
        emailSubject: getCoalescedValue("magicLinkEmailSubject"),
        emailBody: getCoalescedValue("magicLinkEmailBody"),
        conditional: false,
        disableHeaderAndFooter: false,
        hideToggle: true,
        emailRecipient: "customer",
        configValues: {
          subject: "magicLinkEmailSubject",
          body: "magicLinkEmailBody",
        },
      },
      [NotificationEmails.ACCOUNT_CREATION_REMINDER]: {
        enabled: getCoalescedBoolean("sendAccountCreationReminderEmail"),
        title: "Account creation reminder",
        description:
          "Sent to customers who checked out as a guest reminding them to create an account to continue earning points.",
        section: EmailSectionsEnum.REFERRALS,
        emailSubject: getCoalescedValue("accountCreationReminderEmailSubject"),
        emailBody: getCoalescedValue("accountCreationReminderEmailBody"),
        conditional: false,
        disableHeaderAndFooter: false,
        hideToggle: false,
        emailRecipient: "customer",
        configValues: {
          body: "accountCreationReminderEmailBody",
          subject: "accountCreationReminderEmailSubject",
          bccAddress: "accountCreationReminderEmailBccAddress",
          sendBccAddress: "sendAccountCreationReminderEmailBcc",
          toggle: "sendAccountCreationReminderEmail",
        },
      },
      [NotificationEmails.LOYALTY_WELCOME]: {
        enabled: getCoalescedBoolean("sendLoyaltyWelcomeEmail"),
        title: "Loyalty welcome",
        description:
          "Sent to customers who earn points for the very first time introducing them to the loyalty program.",
        emailSubject: getCoalescedValue("loyaltyWelcomeEmailSubject"),
        emailBody: getCoalescedValue("loyaltyWelcomeEmailBody"),
        section: EmailSectionsEnum.REFERRALS,
        disableHeaderAndFooter: true,
        conditional: false,
        hideToggle: false,
        emailRecipient: "customer",
        hidden: isTiersEnabled,
        configValues: {
          body: "loyaltyWelcomeEmailBody",
          subject: "loyaltyWelcomeEmailSubject",
          bccAddress: "loyaltyWelcomeEmailBccAddress",
          sendBccAddress: "sendLoyaltyWelcomeEmailBcc",
          toggle: "sendLoyaltyWelcomeEmail",
        },
      },
      [NotificationEmails.LOYALTY_WELCOME_TIERS]: {
        enabled: getCoalescedBoolean("sendLoyaltyTiersWelcomeEmail"),
        title: "Tiered loyalty welcome",
        description:
          "Sent to customers who earn points for the very first time introducing them to the loyalty program. If tiers are enabled, a summary of the tier program is included.",
        emailSubject: getCoalescedValue("loyaltyTiersWelcomeEmailSubject"),
        emailBody: getCoalescedValue("loyaltyTiersWelcomeEmailBody"),
        section: EmailSectionsEnum.REFERRALS,
        disableHeaderAndFooter: true,
        conditional: false,
        hideToggle: false,
        hidden: !isTiersEnabled,
        emailRecipient: "customer",
        configValues: {
          body: "loyaltyTiersWelcomeEmailBody",
          subject: "loyaltyTiersWelcomeEmailSubject",
          bccAddress: "loyaltyTiersWelcomeBccAddress",
          sendBccAddress: "sendLoyaltyTiersWelcomeEmailBcc",
          toggle: "sendLoyaltyTiersWelcomeEmail",
        },
      },
      [NotificationEmails.LOYALTY_OTP_REDEMPTION]: {
        enabled: getCoalescedBoolean("sendOtpCodeRedeemedEmail"),
        title: "Redemption Code",
        description:
          "Sends a copy of a redemption code via email to a customer who redeems a loyalty item and who does not have an active subscription.",
        section: EmailSectionsEnum.REFERRALS,
        emailSubject: getCoalescedValue("otpCodeRedeemedEmailSubject"),
        emailBody: getCoalescedValue("otpCodeRedeemedEmailBody"),
        conditional: false,
        disableHeaderAndFooter: false,
        hideToggle: false,
        emailRecipient: "customer",
        configValues: {
          body: "otpCodeRedeemedEmailBody",
          subject: "otpCodeRedeemedEmailSubject",
          bccAddress: "otpCodeRedeemedEmailBccAddress",
          sendBccAddress: "sendOtpCodeRedeemedEmailBcc",
          toggle: "sendOtpCodeRedeemedEmail",
        },
      },
      [NotificationEmails.LOYALTY_REWARDS_REMINDER]: {
        enabled: getCoalescedBoolean("sendRewardReminderEmail"),
        title: "Rewards reminder",
        description: "Sent monthly to customers with a point balance to remind them to redeem for rewards.",
        section: EmailSectionsEnum.REFERRALS,
        emailSubject: getCoalescedValue("rewardReminderEmailSubject"),
        emailBody: getCoalescedValue("rewardReminderEmailBody"),
        conditional: false,
        disableHeaderAndFooter: false,
        hideToggle: false,
        emailRecipient: "customer",
        configValues: {
          body: "rewardReminderEmailBody",
          subject: "rewardReminderEmailSubject",
          bccAddress: "rewardReminderEmailBccAddress",
          sendBccAddress: "sendRewardReminderEmailBcc",
          toggle: "sendRewardReminderEmail",
        },
      },
      [NotificationEmails.LOYALTY_BONUS_EARNED]: {
        enabled: getCoalescedBoolean("sendBonusEarnedEmail"),
        title: "Bonus point earn",
        description: "Sent when a customer qualifies for a bonus earn event and receives additional points.",
        section: EmailSectionsEnum.REFERRALS,
        emailSubject: getCoalescedValue("bonusEarnedEmailSubject"),
        emailBody: getCoalescedValue("bonusEarnedEmailBody"),
        conditional: false,
        disableHeaderAndFooter: false,
        hideToggle: false,
        emailRecipient: "customer",
        configValues: {
          body: "bonusEarnedEmailBody",
          subject: "bonusEarnedEmailSubject",
          bccAddress: "bonusEarnedEmailBccAddress",
          sendBccAddress: "sendBonusEarnedEmailBcc",
          toggle: "sendBonusEarnedEmail",
        },
      },
      [NotificationEmails.SUCCESSFUL_REFERRAL]: {
        enabled: getCoalescedBoolean("sendSuccessfulReferralEmail"),
        title: "Successful referral",
        description: "Sent to a customer when someone has successfully used their referral code.",
        section: EmailSectionsEnum.REFERRALS,
        emailSubject: getCoalescedValue("successfulReferralEmailSubject"),
        emailBody: getCoalescedValue("successfulReferralEmailBody"),
        conditional: false,
        disableHeaderAndFooter: false,
        hideToggle: false,
        emailRecipient: "customer",
        configValues: {
          body: "successfulReferralEmailBody",
          subject: "successfulReferralEmailSubject",
          bccAddress: "successfulReferralEmailBccAddress",
          sendBccAddress: "sendSuccessfulReferralEmailBcc",
          toggle: "sendSuccessfulReferralEmail",
        },
      },
      [NotificationEmails.OUT_OF_STOCK_REWARD_ITEM]: {
        enabled: getCoalescedBoolean("sendRewardOutOfStockEmail"),
        title: "Reward item out of stock or deleted",
        description:
          "Sent when a redeemed reward item goes out of stock before a customer's next subscription order is scheduled to bill.",
        section: EmailSectionsEnum.REFERRALS,
        emailSubject: getCoalescedValue("rewardOutOfStockEmailSubject"),
        emailBody: getCoalescedValue("rewardOutOfStockEmailBody"),
        conditional: false,
        hideToggle: false,
        disableHeaderAndFooter: true,
        emailRecipient: "customer",
        configValues: {
          body: "rewardOutOfStockEmailBody",
          subject: "rewardOutOfStockEmailSubject",
          bccAddress: "rewardOutOfStockEmailBccAddress",
          sendBccAddress: "sendRewardOutOfStockEmailBcc",
          toggle: "sendRewardOutOfStockEmail",
        },
      },
      [NotificationEmails.LOYALTY_TIER_ADVANCEMENT]: {
        enabled: getCoalescedBoolean("sendLoyaltyTierAdvancementEmail"),
        title: "Tier advancement",
        description: `Sent to a customer when they earn enough points to advance to the next tier level. Only sent when loyalty tiers are enabled.`,
        emailSubject: getCoalescedValue("loyaltyTierAdvancementEmailSubject"),
        emailBody: getCoalescedValue("loyaltyTierAdvancementEmailBody"),
        section: EmailSectionsEnum.REFERRALS,
        disableHeaderAndFooter: true,
        conditional: false,
        hideToggle: false,
        hidden: !isTiersEnabled,
        emailRecipient: "customer",
        configValues: {
          body: "loyaltyTierAdvancementEmailBody",
          subject: "loyaltyTierAdvancementEmailSubject",
          bccAddress: "loyaltyTierAdvancementBccAddress",
          sendBccAddress: "sendLoyaltyTierAdvancementEmailBcc",
          toggle: "sendLoyaltyTierAdvancementEmail",
        },
      },

      // Merchant Emails
      [NotificationEmails.MIGRATION_SUMMARY]: {
        enabled: getCoalescedBoolean("sendMigrationSummaryEmail"),
        title: "Migration Summary",
        description:
          "Sends when a manual migration has finished that and recaps the migrations' successes/errors with an option to download a report.",
        emailSubject: getCoalescedValue("migrationSummaryEmailSubject"),
        emailBody: getCoalescedValue("migrationSummaryEmailBody"),
        section: EmailSectionsEnum.MIGRATIONS,
        disableHeaderAndFooter: true,
        conditional: false,
        hideToggle: true,
        emailRecipient: "merchant",
        configValues: {
          body: "migrationSummaryEmailBody",
          subject: "migrationSummaryEmailSubject",
          toggle: "sendMigrationSummaryEmail",
        },
      },

      [NotificationEmails.LOYALTY_CURRENCY_REMINDER]: {
        enabled: getCoalescedBoolean("sendLoyaltyCurrencyReminder"),
        title: "Loyalty Currency Reminder",
        description: "Sends when a new market is enabled and the loyalty multiplier needs to be set up for it",
        emailSubject: getCoalescedValue("loyaltyCurrencyReminderEmailSubject"),
        emailBody: getCoalescedValue("loyaltyCurrencyReminderEmailBody"),
        section: EmailSectionsEnum.REFERRALS,
        disableHeaderAndFooter: true,
        conditional: false,
        hideToggle: false,
        emailRecipient: "merchant",
        configValues: {
          body: "loyaltyCurrencyReminderEmailBody",
          subject: "loyaltyCurrencyReminderEmailSubject",
          toggle: "sendLoyaltyCurrencyReminder",
        },
      },

      [NotificationEmails.INSIGHTS_EMAIL]: {
        enabled: getCoalescedBoolean("sendInsightsEmail"),
        preview: false,
        title: "Insights",
        description: "Send weekly Insights email.",
        section: EmailSectionsEnum.INSIGHTS,
        disableHeaderAndFooter: true,
        conditional: false,
        hideToggle: false,
        emailRecipient: "merchant",
        configValues: {
          toggle: "sendInsightsEmail",
        },
      },
    };

    // Removing undefined / hidden and filtering based on recipient
    const configsCopy = cloneDeep(configs);
    for (const [key, value] of Object.entries(configs)) {
      if (!allowShopifyEmails && value.section === EmailSectionsEnum.SENT_FROM_SHOPIFY) {
        delete configsCopy[key];
      }

      if (value.hidden) {
        delete configsCopy[key];
      }

      if (emailRecipient !== "both" && value.emailRecipient !== emailRecipient) {
        delete configsCopy[key];
      }
    }
    return configsCopy;
  }, [emailConfigs, defaultEmailConfig, isTiersEnabled]);
};

export default useGenerateEmailConfigOptions;
