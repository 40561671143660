import { IShopifyData } from "../../../../ShopifyData";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubduedNote } from "../../../components/emailSubduedNote";

// Gifted subscription - Shipped (Giftee)
export function createDefaultShippedGiftEmailBodyGiftee(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(`<%- from.firstName %> has sent you a gift from ${shopifyData.name}`),
    `<% if( note ){ %>
      ${emailSubduedNote("&ldquo;<%- note %>&rdquo;")},
    <% } %>`,
    emailQuestionSection(shopifyData)
  );
}
