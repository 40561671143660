export function passwordlessEmailFrame(text: string) {
  return `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
	<head>
		<meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
		<meta name="viewport" content="width=device-width, initial-scale=1" />
		<title></title>
		<style type="text/css">
			@media only screen and (max-width: 480px) {
				body,
				table,
				td,
				p,
				a,
				li,
				blockquote {
					-webkit-text-size-adjust: none !important;
				}
        a[href] {
          color: inherit !important;
          text-decoration: none !important;
        }
				body {
					width: 100% !important;
					min-width: 100% !important;
          background-color: <%- theming.colors.backgroundColor %>;
				}
				td[id="bodyCell"] {
					padding: 10px !important;
				}
				table.kmMobileHide {
					display: none !important;
				}
				table[class="kmTextContentContainer"] {
					width: 100% !important;
				}
				table[class="kmBoxedTextContentContainer"] {
					width: 100% !important;
				}
				td[class="kmImageContent"] {
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
				img[class="kmImage"] {
					width: 100% !important;
				}
				td.kmMobileStretch {
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
				table[class="kmSplitContentLeftContentContainer"],
				table[class="kmSplitContentRightContentContainer"],
				table[class="kmColumnContainer"],
				td[class="kmVerticalButtonBarContentOuter"]
					table[class="kmButtonBarContent"],
				td[class="kmVerticalButtonCollectionContentOuter"]
					table[class="kmButtonCollectionContent"],
				table[class="kmVerticalButton"],
				table[class="kmVerticalButtonContent"] {
					width: 100% !important;
				}
				td[class="kmButtonCollectionInner"] {
					padding-left: 9px !important;
					padding-right: 9px !important;
					padding-top: 9px !important;
					padding-bottom: 0 !important;
					background-color: transparent !important;
				}
				td[class="kmVerticalButtonIconContent"],
				td[class="kmVerticalButtonTextContent"],
				td[class="kmVerticalButtonContentOuter"] {
					padding-left: 0 !important;
					padding-right: 0 !important;
					padding-bottom: 9px !important;
				}
				table[class="kmSplitContentLeftContentContainer"]
					td[class="kmTextContent"],
				table[class="kmSplitContentRightContentContainer"]
					td[class="kmTextContent"],
				table[class="kmColumnContainer"] td[class="kmTextContent"],
				table[class="kmSplitContentLeftContentContainer"]
					td[class="kmImageContent"],
				table[class="kmSplitContentRightContentContainer"]
					td[class="kmImageContent"] {
					padding-top: 9px !important;
				}
				td[class="rowContainer kmFloatLeft"],
				td.rowContainer.kmFloatLeft,
				td[class="rowContainer kmFloatLeft firstColumn"],
				td.rowContainer.kmFloatLeft.firstColumn,
				td[class="rowContainer kmFloatLeft lastColumn"],
				td.rowContainer.kmFloatLeft.lastColumn {
					float: left;
					clear: both;
					width: 100% !important;
				}
				table[class="templateContainer"],
				table[class="templateContainer brandingContainer"],
				table[class="templateRow"] {
					max-width: 600px !important;
					width: 100% !important;
				}
				h1 {
					font-size: 40px !important;
					line-height: 130% !important;
				}
				h2 {
					font-size: 32px !important;
					line-height: 130% !important;
				}
				h3 {
					font-size: 24px !important;
					line-height: 130% !important;
				}
				h4 {
					font-size: 18px !important;
					line-height: 130% !important;
				}
				td[class="kmTextContent"] {
					font-size: 14px !important;
					line-height: 130% !important;
				}

				table[class="kmTableBlock kmTableMobile"]
					td[class="kmTableBlockInner"] {
					padding-left: 9px !important;
					padding-right: 9px !important;
				}
				table[class="kmTableBlock kmTableMobile"]
					td[class="kmTableBlockInner"]
					[class="kmTextContent"] {
					font-size: 14px !important;
					line-height: 130% !important;
					padding-left: 4px !important;
					padding-right: 4px !important;
				}
			}
			td[class="kmTextContent"] p {
				margin-top: 0px;
				margin-bottom: 0px;
			}

			.tdwidth {
				width: 30%;
			}
			.kmTable th {
				text-align: left !important;
				padding-left: 40px !important;
				color: rgb(48, 46, 116) !important;
			}
			.kmTable td {
				text-align: left !important;
				padding-left: 40px !important;
			}
			.kmTable th:nth-child(2) {
				padding-left: 10px !important;
			}
			.kmTable td:nth-child(2) {
				padding-left: 10px !important;
			}
		</style>
	</head>
  <body style="margin: 0; padding: 0;>
	${text}
  </body>
</html>
  `;
}
