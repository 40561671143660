import { IShopifyData } from "../../../../ShopifyData";

export function getLoyaltyPointsName(pointsVariable: string) {
  return `<%- ${pointsVariable} > 1 ? program.rewardUnitNamePlural : program.rewardUnitNameSingular %>`;
}

export function emailLoyaltyTrophy() {
  return `<img width="14" height="14" alt="loyalty trophy" style="vertical-align-middle; width: 14px; height:14px; object-fit: cover; margin: 0;" src="https://8974553.fs1.hubspotusercontent-na1.net/hubfs/8974553/smartrr-trophy.png" />`;
}

function emailLoyaltyItemProduct(): string {
  return `
    <div style="width: 60px; height: 60px; border-radius: 16px; overflow: hidden;">
      <img src="<%- item.imageUrl %>" width="60" height="60" alt="<%- item.productName %>" style="width:60px; height:60px; object-fit: cover; margin: 0;" />
    </div>
  `;
}

export function emailLoyaltyItemIncentive(): string {
  return `
    <div style="width: 60px; height: 60px; border-radius: 16px; overflow: hidden;">
      <p style="width: 60px; height: 60px; font-size: 12px; font-weight: 700; line-height: 60px; background: <%- item.imageColor %>; text-align: center; color: #000000; margin: 0;"><%- item.imageText %></p>
    </div>
  `;
}

export function emailLoyaltyItem(shopifyData: IShopifyData) {
  return `
  <td style="width: 90px; min-height: 145px; max-height: 185px; gap: 7px">
    <center style="margin-bottom: 7px;">
      <p style="width:fit-content; height: 20px; padding: 0px 10px; gap: 10px; border-radius: 20px; background: #F4F4F4; margin: 0;">
        <span style="font-size: 12px; line-height: 20px; vertical-align: sub;">${emailLoyaltyTrophy()}</span>
        <span style="font-size: 12px; line-height: 20px; margin-left: 3px"><%- item.costInPoints %></span>
      </p>
    </center>
    <center style="margin-bottom: 7px;">
      <% if (item.itemType === "product") { %>
        ${emailLoyaltyItemProduct()}
      <% } else { %>
        ${emailLoyaltyItemIncentive()}
      <% } %>
    </center>
    <div style="width: 100%; height: 30px; margin-bottom: 7px;">
      <center>
        <div style="width: 90px; height: 30px; border-radius: 20px; border: 1px solid #C9C9C9; text-align: center;">
          <a style="font-size:14px; line-height: 30px; color: #000000; text-decoration:none; margin: 0;" href="https://${shopifyData.domain}/account#loyalty">Redeem</a>
        </div>
      </center>
    </div>
    <center>
      <p style="width: 90px; height: 40px; overflow: hidden; font-size: 14px; line-height: 20px; text-align: left; color: #000000; margin: 0;">
        <%- item.productName %>
      </p>
    </center>
  </td>
  `;
}

export function emailLoyaltyItems(variableName: string, shopifyData: IShopifyData) {
  return `
  <% if (${variableName}.length > 0) { %>
    <tr>
      <td style="width: 100%; max-width: 540px; margin-bottom: 30px;">
        <center>
          <% const extraPadding = ((3 - ${variableName}.length % 3) % 3); %>
          <% const maxLength = ${variableName}.length + extraPadding; %>
          <% for (let index = 0; index < maxLength; index++) { %>
            <% const item = ${variableName}[index]; %>
            <% if (index % 3 === 0) { %>
              <% if (index + 3 === maxLength) { %>
                <% const tableWidth = 105 * (3 - extraPadding); %>
                <table width="100%" border="0" cellspacing="0" cellpadding="0" style="width: <%- tableWidth %>px; max-width: <%- tableWidth %>px; margin-bottom: 30px; table-layout: fixed;">
                  <tr>
              <% } else { %>
                <table width="100%" border="0" cellspacing="0" cellpadding="0" style="width: 315px; max-width: 315px; margin-bottom: 30px; table-layout: fixed;">
                  <tr>
              <% } %>
            <% } %>
            <% if (!!item) { %>
              ${emailLoyaltyItem(shopifyData)}
            <% } %>
            <% if (index % 3 === 2) { %>
                </tr>
              </table>
            <% }%>
          <% }%>
        </center>
      </td>
    </tr>
  <% } %>
  `;
}
