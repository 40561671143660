// eslint-disable-line @typescript-eslint/no-unused-vars

import { RequireNilFields } from "../../utils/RequireNilFields";
import { ISetup } from "../Organization";
import { IShopifyData } from "../ShopifyData";
import { ICustomerEmailConfig } from "./types";
import { createDefaultEmailFooter, createDefaultEmailHeader } from "./components/standardHeaderAndFooter";
import { createOneTimeCodeEmailBody } from "./emails/CustomerEmails/Passwordless/OneTimeCode";
import { createMagicLinkEmailBody } from "./emails/CustomerEmails/Passwordless/MagicLink";
import { createDefaultSubscriptionCreateSignupEmailBody } from "./emails/CustomerEmails/SubscriptionCreated/SubscriptionCreateSignup";
import {
  SubscriptionCreateEmail,
  createDefaultSubscriptionCreateEmailBody,
} from "./emails/CustomerEmails/SubscriptionCreated/SubscriptionCreateExisting";
import { createDefaultCancelEmailBody } from "./emails/CustomerEmails/Cancel/CanceledSubscription";
import { createDefaultUpcomingOrderEmailBody } from "./emails/CustomerEmails/Upcoming/UpcomingOrderEmail";
import { createDefaultExpiredPaymentMethodEmailBody } from "./emails/CustomerEmails/Payment/PaymentExpiration";
import { createDefaultShippedGiftEmailBodyGiftee } from "./emails/CustomerEmails/Gift/SubscriptionShippedGiftee";
import { createDefaultGiftIsBeingSentEmailBody } from "./emails/CustomerEmails/Gift/ScheduledGiftGifter";
import { createDefaultShippedGiftEmailBodyGifter } from "./emails/CustomerEmails/Gift/SubscriptionShippedGifter";
import { createDefaultItemOutOfStockEmailBody } from "./emails/CustomerEmails/Item/ItemOutOfStock";
import { createDefaultPausedSubscriptionEmailBody } from "./emails/CustomerEmails/SubscriptionStatus/PausedSubscription";
import { createDefaultAutoResumeSubscriptionEmailBody } from "./emails/CustomerEmails/SubscriptionStatus/AutoResumedSubscription";
import { createDefaultResumedSubscriptionEmailBody } from "./emails/CustomerEmails/SubscriptionStatus/ResumedSubscription";
import { createDefaultSuccessfulReferralEmailBody } from "./emails/CustomerEmails/Loyalty/SuccessfulReferral";
import { createDefaultPaymentFailureEmailBody } from "./emails/CustomerEmails/Payment/PaymentFailure";
import { createDefaultAccountCreationReminderEmailBody } from "./emails/CustomerEmails/Account/AccountCreationReminder";
import { getLoyaltyPointsName } from "./emails/CustomerEmails/Loyalty/components";
import { createDefaultBonusEarnedEmail } from "./emails/CustomerEmails/Loyalty/BonusEarn";
import { createDefaultOtpCodeRedeemedEmail } from "./emails/CustomerEmails/Loyalty/OTPRedeemed";
import { createDefaultRewardsReminderEmail } from "./emails/CustomerEmails/Loyalty/RewardsReminder";
import { createDefaultEmailThemingValues } from "./mocks";
import { renderComponentToHtml } from "./utils";
import { MigrationSummary } from "./emails/MerchantEmails/Migrations/MigrationSummary";
import React from "react";
import { CurrencyReminder } from "./emails/MerchantEmails/Loyalty/CurrencyReminder";
import { OOSRewardItemEmail } from "./emails/CustomerEmails/Loyalty/OOSRewardItem";
import { LoyaltyWelcomeEmail } from "./emails/CustomerEmails/Loyalty/WelcomeEmail";
import { LoyaltyTierAdvancementEmail } from "./emails/CustomerEmails/Loyalty/Tiers/TierAdvancementEmail";

//! Should match `ApplicationEventType` from backend
// TODO: rename to NotificationEmailsEnum
export enum NotificationEmails {
  // Customer Notifications

  // Core Subscriptions
  SUBSCRIPTION_CANCEL = "SUBSCRIPTION_CANCEL",
  SUBSCRIPTION_CREATE = "SUBSCRIPTION_CREATE",
  SUBSCRIPTION_CREATE_SIGNUP = "SUBSCRIPTION_CREATE_SIGNUP",
  SUBSCRIPTION_PAUSE = "SUBSCRIPTION_PAUSE",
  SUBSCRIPTION_AUTO_RESUME = "SUBSCRIPTION_AUTO_RESUME",
  SUBSCRIPTION_ACTIVATE = "SUBSCRIPTION_ACTIVATE",
  SUBSCRIPTION_UPCOMING_ORDER = "SUBSCRIPTION_UPCOMING_ORDER",

  // Gifted Subscriptions
  SUBSCRIPTION_GIFT_GIFTEE = "SUBSCRIPTION_GIFT_GIFTEE",
  SUBSCRIPTION_GIFT_GIFTER = "SUBSCRIPTION_GIFT_GIFTER",
  SUBSCRIPTION_GIFT_CREATE = "SUBSCRIPTION_GIFT_CREATE",

  // Item OOS
  ITEM_OUT_OF_STOCK_CHECK = "ITEM_OUT_OF_STOCK_CHECK",

  //Passwordless Login
  ONE_TIME_CODE = "ONE_TIME_CODE",
  MAGIC_LINK = "MAGIC_LINK",

  // LOYALTY & REWARDS
  SUCCESSFUL_REFERRAL = "SUCCESSFUL_REFERRAL",
  ACCOUNT_CREATION_REMINDER = "ACCOUNT_CREATION_REMINDER",
  LOYALTY_BONUS_EARNED = "LOYALTY_BONUS_EARNED",
  LOYALTY_OTP_REDEMPTION = "LOYALTY_OTP_REDEMPTION",
  LOYALTY_REWARDS_REMINDER = "LOYALTY_REWARDS_REMINDER",
  OUT_OF_STOCK_REWARD_ITEM = "OUT_OF_STOCK_REWARD_ITEM",
  LOYALTY_TIER_ADVANCEMENT = "LOYALTY_TIER_ADVANCEMENT",

  // Loyalty Welcome
  LOYALTY_WELCOME = "LOYALTY_WELCOME",

  LOYALTY_WELCOME_TIERS = "LOYALTY_WELCOME_TIERS",

  //PAYMENT NOTICES
  SUBSCRIPTION_FAILED_PAYMENT = "SUBSCRIPTION_FAILED_PAYMENT", // Sent during OOS Product / variant check
  SHOPIFY_FAILED_PAYMENT = "SHOPIFY_FAILED_PAYMENT", // Sent by Shopify
  PAYMENT_EXPIRATION_NOTICE = "PAYMENT_EXPIRATION_NOTICE", // Sent before creating billing

  // Merchant Emails

  // Migrations
  MIGRATION_SUMMARY = "MIGRATION_SUMMARY",

  // Loyalty
  LOYALTY_CURRENCY_REMINDER = "LOYALTY_CURRENCY_REMINDER",

  // Insights emails
  INSIGHTS_EMAIL = "INSIGHTS_EMAIL",
}

export enum EmailSectionsEnum {
  NEW_SUBSCRIPTION,
  SUBSCRIPTION_MANAGEMENT,
  REFERRALS,
  GIFTED_SUBSCRIPTIONS,
  PASSWORDLESS_LOGIN,
  SENT_FROM_SHOPIFY,

  // Merchant Email
  MIGRATIONS,
  INSIGHTS,
}

export enum ProductUnavailableReason {
  OUT_OF_STOCK = "out_of_stock",
  DELETED = "deleted",
  ARCHIVED = "archived",
}

export function createBasicDefaultEmailConfigPartial(
  shopifyData: IShopifyData,
  setup: ISetup
): RequireNilFields<ICustomerEmailConfig> {
  return {
    sendSubscriptionCreateSignupEmail: true,
    subscriptionCreateSignupEmailSubject: `Your subscription purchase confirmation ${
      shopifyData.name ? `from ${shopifyData.name}` : ""
    }`,
    subscriptionCreateSignupEmailBody: "",
    subscriptionCreateSignupEmailBccAddress: "",
    sendSubscriptionCreateSignupEmailBcc: false,

    sendSubscriptionCreateEmail: true,
    subscriptionCreateEmailSubject: `Your subscription purchase confirmation ${
      shopifyData.name ? `from ${shopifyData.name}` : ""
    }`,
    subscriptionCreateEmailBody: "",
    subscriptionCreateEmailBccAddress: "",
    sendSubscriptionCreateEmailBcc: false,

    sendCancelEmail: true,
    cancelEmailSubject: "We're sad to see you go",
    cancelEmailBody: "",
    cancelEmailBccAddress: "",
    sendCancelEmailBcc: false,

    sendUpcomingOrderEmail: true,
    upcomingOrderEmailSubject: `Your upcoming shipment from ${shopifyData.name}`,
    upcomingOrderEmailBody: "",
    upcomingOrderEmailBccAddress: "",
    sendUpcomingOrderEmailBcc: false,

    sendExpiredPaymentMethodEmail: true,
    expiredPaymentMethodEmailSubject: `Your subscription payment is set to expire`,
    expiredPaymentMethodEmailBody: "",
    expiredPaymentMethodEmailBccAddress: "",
    sendExpiredPaymentMethodEmailBcc: false,

    sendUpdatePaymentEmailOnFailedPayment: true,

    // SENT TO GIFTEE
    sendUpcomingGiftEmail: true,
    upcomingGiftEmailSubject: `Your gift shipment from ${shopifyData.name}`,
    upcomingGiftEmailBody: "",
    upcomingGiftEmailBccAddress: "",
    sendUpcomingGiftEmailBcc: false,

    upcomingOrderEmailDeliveryDelay: 3,

    // SENT TO GIFTER
    sendGiftIsBeingSentEmail: true,
    giftIsBeingSentEmailSubject: `Your gift is being sent`,
    giftIsBeingSentEmailBody: "",
    giftIsBeingSentEmailBccAddress: "",
    sendGiftIsBeingSentEmailBcc: false,

    // SENT TO GIFTER
    sendGifterUpcomingGiftEmail: true,
    gifterUpcomingGiftEmailSubject: "Your gift is on the way",
    gifterUpcomingGiftEmailBody: "",
    gifterUpcomingGiftEmailBccAddress: "",
    sendGifterUpcomingGiftEmailBcc: false,

    itemOutOfStockEmail: true,
    itemOutOfStockEmailSubject: `An item in your subscription<% if(outOfStockItem.unavailableReason === 'out_of_stock'){ %> is out of stock <% } else { %> is no longer available <% } %>`,
    itemOutOfStockEmailBody: "",
    itemOutOfStockEmailBccAddress: "",
    itemOutOfStockEmailBcc: false,

    sendSubscriptionPausedEmail: true,
    sendSubscriptionPausedEmailBcc: false,
    subscriptionPausedEmailSubject: `Your subscription to ${shopifyData.name} has been paused`,
    subscriptionPausedEmailBody: "",
    subscriptionPausedEmailBccAddress: "",

    sendSubscriptionAutoResumeEmail: true,
    sendSubscriptionAutoResumeEmailBcc: false,
    subscriptionAutoResumeEmailSubject: `Your subscription to ${shopifyData.name} has been set to auto-resume`,
    subscriptionAutoResumeEmailBody: "",
    subscriptionAutoResumeEmailBccAddress: "",

    sendSubscriptionResumedEmail: true,
    sendSubscriptionResumedEmailBcc: false,
    subscriptionResumedSubject: `Your subscription to ${shopifyData.name} has been reactivated`,
    subscriptionResumedEmailBody: "",
    subscriptionResumedEmailBccAddress: "",

    emailHeader: createDefaultEmailHeader(shopifyData),
    emailFooter: createDefaultEmailFooter(shopifyData),
    emailTheming: createDefaultEmailThemingValues(setup),

    isArpuEnabled: false,

    // PASSWORDLESS
    oneTimeCodeEmailSubject: `Your one-time code for ${shopifyData.name}`,
    oneTimeCodeEmailBody: "",
    magicLinkEmailBody: "",
    magicLinkEmailSubject: `Log into ${shopifyData.name}`,

    // REFERRALS
    sendSuccessfulReferralEmail: true,
    sendSuccessfulReferralEmailBcc: false,
    successfulReferralEmailBody: "",
    successfulReferralEmailSubject: `<%- referrals.referee.firstName ?? 'A friend' %> has used your referral code for ${shopifyData.name}`,
    successfulReferralEmailBccAddress: "",

    // PAYMENT FAILURE
    sendPaymentFailureEmail: true,
    sendPaymentFailureEmailBcc: false,
    paymentFailureEmailBody: "",
    paymentFailureEmailSubject: `<%- customer.firstName ? customer.firstName + ', there' : "There" %> was a problem with your subscription payment`,
    paymentFailureEmailBccAddress: "",

    // ACCOUNT CREATION REMINDER
    sendAccountCreationReminderEmail: true,
    sendAccountCreationReminderEmailBcc: false,
    accountCreationReminderEmailSubject: "Create an account to use your points",
    accountCreationReminderEmailBody: "",
    accountCreationReminderEmailBccAddress: "",

    // BONUS EARNED EMAIL
    sendBonusEarnedEmail: true,
    sendBonusEarnedEmailBcc: false,
    bonusEarnedEmailSubject: `You earned an extra <%- bonusEarnedReminder.pointsEarned %> ${getLoyaltyPointsName(
      "bonusEarnedReminder.pointsEarned"
    )}!`,
    bonusEarnedEmailBody: "",
    bonusEarnedEmailBccAddress: "",

    //OTP REDEEMED EMAIL
    sendOtpCodeRedeemedEmail: true,
    sendOtpCodeRedeemedEmailBcc: false,
    otpCodeRedeemedEmailSubject: "Your reward is ready",
    otpCodeRedeemedEmailBody: "",
    otpCodeRedeemedEmailBccAddress: "",

    //REWARDS REMINDER EMAIL
    sendRewardReminderEmail: true,
    sendRewardReminderEmailBcc: false,
    rewardReminderEmailSubject: `Reminder: You have <%- rewardsReminder.customerBalance %> ${getLoyaltyPointsName(
      "rewardsReminder.customerBalance"
    )} available`,
    rewardReminderEmailBody: "",
    rewardReminderEmailBccAddress: "",

    // OUT OF STOCK REWARD ITEM EMAIL
    sendRewardOutOfStockEmail: true,
    sendRewardOutOfStockEmailBcc: false,
    rewardOutOfStockEmailSubject: `An update on your loyalty reward`,
    rewardOutOfStockEmailBody: "",
    rewardOutOfStockEmailBccAddress: "",

    sendLoyaltyCurrencyReminder: true,
    loyaltyCurrencyReminderEmailSubject:
      "Loyalty: New <%- currencies %> currency <%- currenciesCount === 1 ? 'multiplier' : 'multipliers' %> needed for <%- orgName %>",
    loyaltyCurrencyReminderEmailBody: "",

    sendMigrationSummaryEmail: true,
    migrationSummaryEmailSubject: "Your migration is complete - <%- migration.completionDate %>",
    migrationSummaryEmailBody: "",

    sendInsightsEmail: false,

    sendLoyaltyWelcomeEmail: true,
    loyaltyWelcomeEmailSubject: "You've earned your first points",
    loyaltyWelcomeEmailBody: "",
    sendLoyaltyWelcomeEmailBcc: false,
    loyaltyWelcomeEmailBccAddress: "",

    sendLoyaltyTiersWelcomeEmail: true,
    loyaltyTiersWelcomeEmailSubject:
      "You've earned your first points and are in <%- loyalty.tiers.currentTier.tierName %>",
    loyaltyTiersWelcomeEmailBody: "",
    sendLoyaltyTiersWelcomeEmailBcc: false,
    loyaltyTiersWelcomeBccAddress: "",

    sendLoyaltyTierAdvancementEmail: true,
    loyaltyTierAdvancementEmailSubject: "You've moved up to <%- loyalty.tiers.currentTier.tierName %> tier!",
    loyaltyTierAdvancementEmailBody: "",
    sendLoyaltyTierAdvancementEmailBcc: false,
    loyaltyTierAdvancementBccAddress: "",
  };
}

export async function createDefaultEmailConfig(
  shopifyData: IShopifyData,
  setup: ISetup,
  // TODO: Remove once full refactor is complete and flag is removed
  enableNewEmails = false
): Promise<RequireNilFields<ICustomerEmailConfig>> {
  return {
    ...createBasicDefaultEmailConfigPartial(shopifyData, setup),
    subscriptionCreateSignupEmailBody: createDefaultSubscriptionCreateSignupEmailBody(shopifyData),
    subscriptionCreateEmailBody: enableNewEmails
      ? await renderComponentToHtml(<SubscriptionCreateEmail shopifyData={shopifyData} />)
      : createDefaultSubscriptionCreateEmailBody(shopifyData),
    cancelEmailBody: createDefaultCancelEmailBody(shopifyData),
    upcomingOrderEmailBody: createDefaultUpcomingOrderEmailBody(shopifyData),
    expiredPaymentMethodEmailBody: createDefaultExpiredPaymentMethodEmailBody(shopifyData),
    upcomingGiftEmailBody: createDefaultShippedGiftEmailBodyGiftee(shopifyData),
    giftIsBeingSentEmailBody: createDefaultGiftIsBeingSentEmailBody(shopifyData),
    gifterUpcomingGiftEmailBody: createDefaultShippedGiftEmailBodyGifter(shopifyData),
    itemOutOfStockEmailBody: createDefaultItemOutOfStockEmailBody(shopifyData),
    subscriptionPausedEmailBody: createDefaultPausedSubscriptionEmailBody(shopifyData),
    subscriptionAutoResumeEmailBody: createDefaultAutoResumeSubscriptionEmailBody(shopifyData),
    subscriptionResumedEmailBody: createDefaultResumedSubscriptionEmailBody(shopifyData),
    oneTimeCodeEmailBody: createOneTimeCodeEmailBody(shopifyData),
    magicLinkEmailBody: createMagicLinkEmailBody(shopifyData),
    successfulReferralEmailBody: createDefaultSuccessfulReferralEmailBody(shopifyData),
    paymentFailureEmailBody: createDefaultPaymentFailureEmailBody(shopifyData),
    accountCreationReminderEmailBody: createDefaultAccountCreationReminderEmailBody(shopifyData),
    bonusEarnedEmailBody: createDefaultBonusEarnedEmail(shopifyData),
    otpCodeRedeemedEmailBody: createDefaultOtpCodeRedeemedEmail(shopifyData),
    rewardReminderEmailBody: createDefaultRewardsReminderEmail(shopifyData),
    loyaltyWelcomeEmailBody: await renderComponentToHtml(<LoyaltyWelcomeEmail shopifyData={shopifyData} />),
    loyaltyTiersWelcomeEmailBody: await renderComponentToHtml(
      <LoyaltyWelcomeEmail shopifyData={shopifyData} tiersEnabled />
    ),
    loyaltyTierAdvancementEmailBody: await renderComponentToHtml(
      <LoyaltyTierAdvancementEmail shopifyData={shopifyData} />
    ),
    rewardOutOfStockEmailBody: await renderComponentToHtml(<OOSRewardItemEmail shopifyData={shopifyData} />),
    loyaltyCurrencyReminderEmailBody: await renderComponentToHtml(<CurrencyReminder />),
    migrationSummaryEmailBody: await renderComponentToHtml(<MigrationSummary />),
  };
}
