import { NotificationEmails } from "@smartrr/shared/entities/CustomerEmailConfig/schemas";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { isEqual, omit } from "lodash";
import { create } from "zustand";
import { useMemo } from "react";

interface EmailNotificationStoreProps {
  isLoading: boolean;
  showError: boolean;
  errorMessage: string;

  emailConfigs: NotificationEmails.Type | null;
  storedEmailConfigs: NotificationEmails.Type | null;

  actions: {
    initialize: () => void;
    update: (updatedConfig: NotificationEmails.Type) => void;
  };
  internal: {
    initializeWith(emailConfig: NotificationEmails.ApiResponseType): void;
    whileLoading<Type>(fn: () => Promise<Type>): Promise<Type | null>;
  };
}

const useEmailNotificationStore = create<EmailNotificationStoreProps>()((set, get) => ({
  isLoading: false,
  emailConfigs: null,
  storedEmailConfigs: null,
  errorMessage: "",
  showError: false,
  actions: {
    async initialize() {
      const data = await typedFrontendVendorApi.getReq("/customer-email-config");
      if (data.type === "error") {
        set({
          showError: true,
          errorMessage: data.message,
        });
      }
      if (data.type !== "success") {
        return;
      }

      get().internal.initializeWith(data.body);
    },
    async update(updatedConfig) {
      const internal = get().internal;
      if (!updatedConfig) {
        return;
      }

      const parsedConfig = NotificationEmails.baseSchema.safeParse(updatedConfig);

      if (!parsedConfig.success) {
        set({
          showError: true,
          errorMessage: parsedConfig.error.message,
        });
        return;
      }

      const response = await typedFrontendVendorApi.postReq("/customer-email-config", {
        reqBody: {
          ...parsedConfig.data,
        },
      });

      if (response.type !== "success") {
        set({
          showError: true,
          errorMessage: response.message,
        });
        return;
      }
      set({
        showError: false,
        errorMessage: "",
      });

      internal.initializeWith(response.body);
    },
  },
  internal: {
    initializeWith(emailConfig) {
      const parsedConfigs = NotificationEmails.baseSchema.safeParse(emailConfig);
      if (!parsedConfigs.success) {
        set({
          showError: true,
          errorMessage: parsedConfigs.error.message,
        });
        return;
      }

      set({
        showError: false,
        errorMessage: "",
        emailConfigs: parsedConfigs.data,
        storedEmailConfigs: parsedConfigs.data,
      });
    },
    async whileLoading<Type>(fn: () => Promise<Type>): Promise<Type | null> {
      if (get().isLoading) {
        return null;
      }
      const response = await fn();
      set({
        isLoading: false,
      });
      return response;
    },
  },
}));

const initialStoreState = useEmailNotificationStore.getState();

export const EmailNotificationAccessStore = {
  useActions() {
    return useEmailNotificationStore(state => state.actions);
  },
  useLoading() {
    return useEmailNotificationStore(state => state.isLoading);
  },
  useEmailConfigs(): {
    emailConfigs: NotificationEmails.Type | null;
    initialEmailConfigs: NotificationEmails.Type | null;
  } {
    const emailConfigs = useEmailNotificationStore(state => state.emailConfigs);
    const initialEmailConfigs = useEmailNotificationStore(state => state.storedEmailConfigs);

    return { emailConfigs, initialEmailConfigs };
  },
  useErrors() {
    const showError = useEmailNotificationStore(state => state.showError);
    const errorMessage = useEmailNotificationStore(state => state.errorMessage);

    return {
      showError,
      errorMessage,
    };
  },
  /**
   * TODO: Taking modifiedConfig as a param is temporary.
   * Once all email input has been migrated away from fromik / useTypedForm input will be obtained internally through the store and this check will occur after input change instead of calling hook.
   */
  useHasChanges(modifiedConfig: NotificationEmails.Type) {
    const { emailConfigs } = EmailNotificationAccessStore.useEmailConfigs();

    return useMemo(() => {
      if (!modifiedConfig) {
        return false;
      }
      return !isEqual(
        omit(emailConfigs, ["deletedAt", "updatedDate"]),
        omit(modifiedConfig, ["deletedAt", "updatedDate"])
      );
    }, [emailConfigs, modifiedConfig]);
  },
  testing: {
    store() {
      return useEmailNotificationStore.getState();
    },
    actions() {
      return {
        actions: useEmailNotificationStore.getState().actions,
        internal: useEmailNotificationStore.getState().internal,
      };
    },
    reset(emailConfig?: NotificationEmails.ApiResponseType) {
      useEmailNotificationStore.setState(initialStoreState);
      if (emailConfig) {
        useEmailNotificationStore.getState().internal.initializeWith(emailConfig);
      }
    },
  },
};
