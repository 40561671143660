import { Button } from "@react-email/components";
import React from "react";
import SMARTRR_COLORS from "@smartrr/shared/branding/colors";

export const RoundedButton = ({ children, style, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <Button
      style={{
        color: SMARTRR_COLORS.duskyPurple,
        textAlign: "center",
        fontFamily: "Arial,sans-serif",
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "100%",
        maxWidth: "500px",
        letterSpacing: "-0.15px",
        padding: "10px 20px",
        backgroundColor: SMARTRR_COLORS.chartreuse,
        borderRadius: "44px",
        ...style,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
