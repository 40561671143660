import React from "react";
import { Section } from "@react-email/components";
import { formatTemplateVariable } from "../../../utils";
import { IShopifyData } from "../../../../ShopifyData";

export const ShopHeaderWithLogo = ({ shopifyData }: { shopifyData: IShopifyData }) => {
  return (
    <Section>
      {"<% if(theming.useHeaderAndFooter ) { %>"}
      <Section
        className="text fz-20 lh-23 pb-30"
        style={{
          borderBottom: "1px solid #ececec",
          color: formatTemplateVariable("theming.colors.primaryTextColor"),
          fontFamily: "Arial, sans-serif",
          textAlign: "left",
          fontSize: "20px",
          lineHeight: "23px",
        }}
      >
        <div
          style={{
            paddingBottom: "30px",
          }}
        >
          {"<% if(theming.logo) { %>"}
          <img src="<%- theming.logo %>" width="<%- theming.logoWidth %>" alt={`${shopifyData.name} Logo`} />
          {"<% } else { %>"}
          {shopifyData.name}
          {"<% } %>"}
        </div>
      </Section>
      {"<% } %>"}
    </Section>
  );
};
