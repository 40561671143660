import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubduedNote } from "../../../components/emailSubduedNote";
import { emailLoyaltyItems, getLoyaltyPointsName } from "./components";

export function createDefaultRewardsReminderEmail(shopifyData: IShopifyData): string {
  return emailFrame(
    emailHeader(
      `Reminder: You have <%- rewardsReminder.customerBalance %> ${getLoyaltyPointsName(
        "rewardsReminder.customerBalance"
      )} available`
    ),
    emailSubduedNote(
      `<%= rewardsReminder.currentMonth.toUpperCase() %> <%- rewardsReminder.customerBalance > 1 ? program.rewardUnitNamePlural.toUpperCase() : program.rewardUnitNameSingular.toUpperCase() %> BALANCE: <%- rewardsReminder.customerBalance %>`
    ),
    emailBodySection(
      `Don’t miss out! Redeem or save your <%- program.rewardUnitNamePlural %> for some of these great rewards on your next order: `
    ),
    emailLoyaltyItems("rewardsReminder.loyaltyItems", shopifyData),
    emailButton("View all rewards", `https://${shopifyData.domain}/account#loyalty`),
    emailQuestionSection(shopifyData)
  );
}
