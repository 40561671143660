import { IShopifyData } from "../../../../ShopifyData";
import { emailBasicRow } from "../../../components/emailBasicRow";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailIfElse } from "../../../components/emailIfElse";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubscriptionNotice } from "../../../components/emailSubscriptionNotice";
import { emailCombine } from "../../../utils";

export function createDefaultOtpCodeRedeemedEmail(shopifyData: IShopifyData): string {
  return emailFrame(
    emailHeader("Your reward is ready"),
    emailIfElse(
      `otpRedemption.itemRedeemed.itemType === "product"`,
      emailCombine(
        emailBodySection(
          `Hi <%- customer.firstName %>, use the below code on your next order for a free <strong><%- otpRedemption.itemRedeemed.productName %><strong>: `
        ),
        emailBasicRow(`
          <center style="margin-bottom: 7px;">
            <div style="width: 180px; height: 180px; border-radius: 50px; overflow: hidden;">
              <img src="<%- otpRedemption.itemRedeemed.imageUrl %>" width="180" height="180" alt="<%- otpRedemption.itemRedeemed.productName %>" style="width:180px; height:180px; object-fit: cover; margin: 0;" />
            </div>
          </center>
        `)
      ),
      emailCombine(
        emailBodySection(
          `Hi <%- customer.firstName %>, use the below code on your next order for <strong><%- otpRedemption.itemRedeemed.productName %><strong>: `
        ),
        emailBasicRow(`
          <center style="margin-bottom: 7px;">
            <div style="width: 180px; height: 180px; border-radius: 50px; overflow: hidden;">
              <p style="width: 180px; height: 180px; font-size: 40px; line-height: 180px; background: <%- otpRedemption.itemRedeemed.imageColor %>; text-align: center; color: #000000; margin: 0;"><%- otpRedemption.itemRedeemed.imageText %></p>
            </div>
          </center>
        `)
      )
    ),
    emailBasicRow(`
      <center style="width: 100%; margin: 20px 0 30px 0;">
        <div style="width: 100%; height: 100px; border-radius: 10px; overflow: hidden;">
          <p style="width: 100%; height: 100px; font-size: 20px; line-height: 100px; text-align: center; background-color: #0000000A; font-weight: 700; margin: 0;">
            <%- otpRedemption.discountCode.toUpperCase() %>
          </p>
        </div>
      </center>
    `),
    emailIfElse(
      `otpRedemption.itemRedeemed.itemType === "product"`,
      emailBodySection(
        `This code is unique to your account and can't be used by others. To use, add <strong><%- otpRedemption.itemRedeemed.productName %></strong> to your cart and use the code above at checkout.`
      ),
      emailBodySection(`This code is unique to your account and can't be used by others.`)
    ),
    emailQuestionSection(shopifyData),
    emailButton("Shop now", `https://${shopifyData.domain}/`),
    emailSubscriptionNotice(shopifyData)
  );
}
