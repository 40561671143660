// Generate Subscription Products
export const emailProductData = (): string =>
  `<% if(subscription && subscription.lines.length){ %>
    <tr>
      <td class="pb-20" align="left" style="padding-bottom: 20px;">
        <% subscription.lines.forEach((line) => { %>
          <% if(line.variant.image) { %>
            <div class="box" style="float: left; display: inline;">
              <table width="56" align="left" class="box-inner" cellpadding="0" cellspacing="0" border="0" style="border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt; float: left; width: 56px;">
                <tr>
                  <td class="img pb-10" style="font-size:0pt; line-height:0pt; text-align:left; padding-bottom: 10px;">
                    <img src="<%- line.variant.image %>" style="border-radius: 12px; object-fit: cover;" width="44" height="44" border="0" alt="<%- line.variant.name %>" />
                  </td>
                  <td width="10" style="padding-bottom: 10px; font-size: 0pt; line-height: 0pt;">&nbsp;</td>
                </tr>
              </table>
            </div>
            <% } %>
        <% }); %>
    </td>
    </tr>
    <tr>
      <td class="pb-20" align="left" style="padding-bottom: 20px;">
          <% subscription.lines.forEach((line) => { %>
              <div class="box" style="float: left; display: inline;">
                  <table width="510" align="left" class="box-inner" cellpadding="0" cellspacing="0" border="0" style="border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt; float: left; width: 510px;">
                    <td class="text fz-12 lh-14 c-grey44 l-grey44 pb-15" style="font-family:Arial, sans-serif; text-align:left; mso-line-height-rule:exactly; font-size: 12px; line-height: 14px; color:<%- theming.colors.primaryTextColor %>; padding-bottom: 5px;">
                      <%- line.product.name %><% if(line.variant.name !== 'Default Title'){ %> - <%- line.variant.name %><% } %> - (<%- line.quantity %>)
                    </td>
                  </table>
                </div>
          <% }); %>
        </td>
    </tr>
<% } %>`;
