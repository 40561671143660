import { IShopifyData } from "@smartrr/shared/entities/ShopifyData";
import { emailBodySection } from "@smartrr/shared/entities/CustomerEmailConfig/components/emailBody";
import { emailButton } from "@smartrr/shared/entities/CustomerEmailConfig/components/emailButton";
import { emailFrame } from "@smartrr/shared/entities/CustomerEmailConfig/components/emailFrame";
import { emailHeader } from "@smartrr/shared/entities/CustomerEmailConfig/components/emailHeader";
import { emailQuestionSection } from "@smartrr/shared/entities/CustomerEmailConfig/components/emailQuestions";

// Subscription created - New customer
export function createDefaultSubscriptionCreateSignupEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader("Create an account to manage your subscription"),
    emailBodySection(
      `Hi <%- customer.firstName %>, this email confirms your recurring subscription from ${shopifyData.name}.`
    ),
    emailBodySection(
      "To manage your subscription in the future, make sure to create an account below using the email address associated with your order: <%- customer.email %>."
    ),
    emailQuestionSection(shopifyData),
    emailButton("Create an account", `https://${shopifyData.domain}/account`)
  );
}
