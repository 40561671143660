import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailProductData } from "../../../components/emailProductData";
import { emailQuestionSection } from "../../../components/emailQuestions";

// Gifted subscription - Shipped (Gifter)
export function createDefaultShippedGiftEmailBodyGifter(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(`Your gift to <%- to.firstName %> is on the way`),
    emailProductData(),
    emailBodySection(
      "Your gifted order will arrive soon. Don't worry, we've also sent an email to <%- to.firstName %> letting them know something is coming—but haven't told them what's inside!"
    ),
    emailButton("Track shipment", `https://${shopifyData.domain}/account`),
    emailQuestionSection(shopifyData)
  );
}
