import { isEmpty } from "lodash";
import { emailCombineText } from "../utils";

export const emailBodySection = (...input: string[]): string => {
  const text = emailCombineText(input);
  return `<% if(${!isEmpty(text)}){ %>
    <tr>
      <td class="text fz-16 lh-19 pb-30" style="color:<%- theming.colors.primaryTextColor %>; font-family:Arial, sans-serif; text-align:left; mso-line-height-rule:exactly; font-size: 16px; line-height: 19px; padding-bottom: 30px;">
        ${text}
      </td>
    </tr>
  <% } %>`;
};
