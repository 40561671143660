import { Img, Section } from "@react-email/components";
import React from "react";
import SMARTRR_COLORS from "@smartrr/shared/branding/colors";
import { FRONT_END_HOST_BASE } from "../../../constants";

export const SmartrrLogo = () => (
  <Img
    style={{ margin: "auto !important" }}
    src={`https://${FRONT_END_HOST_BASE}/smartrr-new.png`}
    width="105px"
  />
);

export const SmartrrLogoEmailBanner = () => (
  <Section
    style={{
      width: "100% !important",
      height: "100px",
      backgroundColor: `${SMARTRR_COLORS.duskyPurple} !important`,
      borderRadius: "10px",
      marginBottom: "20px",
    }}
  >
    <SmartrrLogo />
  </Section>
);
