import { isEmpty } from "lodash";
import { IShopifyData } from "../../ShopifyData";

export const emailQuestionSection = (shopifyData: IShopifyData) =>
  `<% if(${!isEmpty(shopifyData)}){ %>
    <tr>
        <td class="text fz-16 lh-19 pb-30" style="color:<%- theming.colors.primaryTextColor %>; font-family:Arial, sans-serif; text-align:left; mso-line-height-rule:exactly; font-size: 16px; line-height: 19px; padding-bottom: 30px;">
          Have a question? Email us at <a href="mailto:${
            shopifyData.email
          }" target="_blank" class="link-u" style="color:<%- theming.colors.primaryTextColor %>; text-decoration:underline;"><span class="link-u" style="color:<%- theming.colors.primaryTextColor %>; text-decoration:underline;">${
            shopifyData.email
          }</span></a>.
        </td>
      </tr>
    <% } %>`;
