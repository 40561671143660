import { IShopifyData } from "../../../../ShopifyData";
import React from "react";
import { Section, Img } from "@react-email/components";
import BasicCustomerEmailFrame from "../../../components/BasicCustomerEmailFrame";
import { BodyLg, HeadingLg } from "../../../components/Text";
import { formatTemplateVariable } from "../../../utils";
import { RoundedButton } from "../../../components/RoundedButton";
import { ShopHeaderWithLogo } from "../components/ShopHeaderWithLogo";
import { ShopFooterWithAddress } from "../components/ShopFooterWithAddress";

export function OOSRewardItemEmail({ shopifyData }: { shopifyData: IShopifyData }) {
  return (
    <BasicCustomerEmailFrame
      emailPreviewText="An update on your loyalty reward"
      emailTitle="An update on your loyalty reward"
    >
      <ShopHeaderWithLogo shopifyData={shopifyData} />
      <HeadingLg
        data-testid="migration-summary__header"
        style={{ color: formatTemplateVariable("theming.colors.primaryTextColor") }}
      >
        An update on your loyalty reward
      </HeadingLg>
      <Section style={{ paddingBottom: "30px" }}>
        {` <% if(outOfStockItem && outOfStockItem.image){ %> `}
        <Img
          src="<%- outOfStockItem.image %>"
          style={{
            borderRadius: "12px",
            objectFit: "cover",
            width: "44px",
            height: "44px",
          }}
          alt="<%- outOfStockItem.name %>"
        />
        {` <% } %> `}
      </Section>
      <BodyLg
        data-testid="migration-summary__file-name"
        style={{
          margin: 0,
          paddingBottom: "16px",
          color: formatTemplateVariable("theming.colors.primaryTextColor"),
        }}
      >
        Hi {formatTemplateVariable("customer.firstName")}, we wanted to let you know that the loyalty reward you
        recently redeemed, {formatTemplateVariable("outOfStockItem.name")}, is currently out of stock.
      </BodyLg>
      <BodyLg
        data-testid="migration-summary__completion-date"
        style={{
          margin: 0,
          paddingBottom: "20px",
          color: formatTemplateVariable("theming.colors.primaryTextColor"),
        }}
      >
        However, there&apos;s no need to worry! If the item becomes available before one of your upcoming
        subscription orders is processed, we will make sure to include it in that order.
      </BodyLg>

      <RoundedButton
        data-testid="migration-summary__download-button"
        href={`https://${shopifyData.domain}/account`}
        style={{
          width: "540px",
          marginBottom: "30px",
          background: formatTemplateVariable("theming.colors.buttonBackgroundColor"),
          color: formatTemplateVariable("theming.colors.buttonTextColor"),
        }}
      >
        View account
      </RoundedButton>
      <ShopFooterWithAddress shopifyData={shopifyData} />
    </BasicCustomerEmailFrame>
  );
}
