import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailProductData } from "../../../components/emailProductData";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubHeader } from "../../../components/emailSubHeader";

export function createDefaultResumedSubscriptionEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(`Your subscription has been reactivated`),
    emailSubHeader("REACTIVATED ITEMS:"),
    emailProductData(),
    emailBodySection(
      `Hi <%- customer.firstName %>, your subscription has been successfully reactivated. Your next order is scheduled to process on: <%- subscription.nextBillingDate %>`
    ),
    emailBodySection("To view your subscription, visit your account portal below."),
    emailButton("Log into my account", `https://${shopifyData.domain}/account`),
    emailQuestionSection(shopifyData)
  );
}
