import { isEmpty } from "lodash";

export const emailSubHeader = (text: string): string =>
  `<% if(${!isEmpty(text)}){ %>
    <tr>
      <td class="text fz-12 lh-14 c-grey44 l-grey44 pb-15" style="font-family:Arial, sans-serif; text-align:left; mso-line-height-rule:exactly; font-size: 12px; line-height: 14px; color:<%- theming.colors.primaryTextColor %>; padding-bottom: 15px; text-transform:uppercase;">
        ${text}
      </td>
    </tr>
  <% } %>`;
