import React from "react";
import { Section } from "@react-email/components";
import { SMARTRR_COLORS } from "@smartrr/shared/branding/colors";

export const EmailCard = ({
  children,
  style,
  ...props
}: { children: JSX.Element; style?: React.CSSProperties } & React.RefAttributes<HTMLTableElement>) => {
  return (
    <Section
      style={{
        borderRadius: "8px",
        backgroundColor: SMARTRR_COLORS.white,
        overflow: "hidden",
        boxShadow: "0px 1px 16px 0px rgba(205, 127, 50, 0.12), 0px 1px 8px 0px rgba(0, 0, 0, 0.08)",
        ...style,
      }}
      {...props}
    >
      {children}
    </Section>
  );
};
