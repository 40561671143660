import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubscriptionNotice } from "../../../components/emailSubscriptionNotice";

export function createDefaultAccountCreationReminderEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(`Create an account to use your points`),
    emailBodySection(
      `Hi <%- customer.firstName %>, you just earned <%- accountCreationReminder.pointsEarned %> points from ${shopifyData.name}.`
    ),
    emailBodySection(
      `In order to use your points for great rewards, please make sure to create an account below using the email address associated with your original order: <%- customer.email %>`
    ),
    emailQuestionSection(shopifyData),
    emailButton("Create an account", `https://${shopifyData.domain}/account/register`),
    emailSubscriptionNotice(shopifyData)
  );
}
