import { IShopifyData } from "../../../../ShopifyData";
import { emailBodySection } from "../../../components/emailBody";
import { emailButton } from "../../../components/emailButton";
import { emailFrame } from "../../../components/emailFrame";
import { emailHeader } from "../../../components/emailHeader";
import { emailProductData } from "../../../components/emailProductData";
import { emailQuestionSection } from "../../../components/emailQuestions";
import { emailSubHeader } from "../../../components/emailSubHeader";

export function createDefaultPausedSubscriptionEmailBody(shopifyData: IShopifyData) {
  return emailFrame(
    emailHeader(`Your subscription has been paused`),
    emailSubHeader("PAUSED ITEMS:"),
    emailProductData(),
    emailBodySection(
      `Hi <%- customer.firstName %>, this email confirms your subscription has been paused.<% if (subscription.unpauseDate) { %> Your subscription is set to auto-resume on <%= subscription.unpauseDate %><% } %>`
    ),
    emailBodySection(
      "Want to unpause? Visit your account portal below and resume your subscription immediately at any time."
    ),
    emailButton("Resume subscription", `https://${shopifyData.domain}/account`),
    emailQuestionSection(shopifyData)
  );
}
